import { email, required, validate } from "common/validation";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";

const ResetPasswordForm = ({ onSubmit, defaultValues, error }) => {
  return (
    <Form
      mode="create"
      onSubmit={onSubmit}
      submitLabel="Reset Password"
      defaultValues={defaultValues}
    >
      <Error>{error}</Error>
      <Input
        label="Email"
        field="email"
        type="email"
        validate={validate(required(), email())}
        autoComplete="email"
      />
    </Form>
  );
};

export default ResetPasswordForm;
