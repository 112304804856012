import { API } from "common";

export const submitLogin = ({ email, password }) => {
  return API.post("/login", {
    email,
    password,
  });
};

export const refreshAccessToken = ({ refreshToken }) => {
  return API.post("/token", {
    refresh_token: refreshToken,
  });
};

export const passwordReset = ({ email, origin }) => {
  return API.post(
    "/password/reset",
    {
      email,
    },
    {
      params: {
        origin,
      },
    }
  );
};

export const createPassword = ({ mode, oobCode, newPassword, email }) => {
  return API.post(
    "/password/reset-confirm",
    {
      email,
      newPassword,
    },
    {
      params: {
        mode,
        oobCode,
      },
    }
  );
};

export const updatePassword = ({
  oldPassword,
  newPassword,
  newPasswordConfirm,
  accessToken,
}) => {
  return API.put(
    "/password",
    {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
