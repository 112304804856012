import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import useFlash from "core/hooks/useFlash";
import { FormContainer } from "core/components/Form";
import Error from "core/components/Error";
import { removeAliases } from "modules/config/actions";
import BasicNPIForm from "modules/config/BasicNPIForm";
import NPIValidationForm from "modules/config/NPIValidationForm";
import { Utils } from "common";
import CloseButton from "core/components/CloseButton";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const RemoveAliases = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { npi, orgId: organizationId } = useParams();
  const defaultNPIs = npi || location.state?.npis?.join("\n") || "";
  const queryKey = location.state?.queryKey;

  const { toaster } = useToaster();
  const { error, setError } = useFlash();
  const queryClient = useQueryClient();
  const apiCall = useAuthenticatedMutation((user) =>
    removeAliases({ ...user, organizationId })
  );
  const mutation = useMutation({
    mutationFn: apiCall,
    onMutate: async (data) => {
      toaster.info({
        message: "Aliases are being removed.",
        isInfinite: true,
      });
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Snaphot the previous value
      const previousAliasData = queryClient.getQueryData(queryKey);

      // Optimistically update
      queryClient.setQueryData(queryKey, (previousAliases) => {
        const removedNPIs = data.npis;
        const oldRecords = previousAliases.rows;
        const keepRecords = oldRecords.filter(
          (record) => !removedNPIs.includes(record.provider_npi)
        );

        return { ...previousAliases, rows: keepRecords };
      });

      closeForm();
      return { previousAliasData };
    },
    onSettled: (data, error, variables, context) => {
      toaster.clear();
      queryClient.invalidateQueries({ queryKey });

      if (error) {
        toaster.error({ message: error.message });
        queryClient.setQueryData(queryKey, context.previousAliasData);
      } else {
        toaster.success({
          message: "Aliases have been removed.",
        });
      }
    },
  });

  const [formStep, setFormStep] = useState(0);
  const [validNPIs, setValidNPIs] = useState([]);
  const [invalidNPIs, setInvalidNPIs] = useState([]);

  const checkNPIs = (payload) => {
    const { npis } = payload;
    setError(null);

    const newlineSplitNPIs = npis.split("\n");
    const trimmedList = newlineSplitNPIs
      .filter((npi) => npi)
      .map((npi) => npi.trim());

    if (trimmedList.length === 0) return "Add NPIs to complete this form.";

    // Validate NPI list
    let valid = [];
    let invalid = [];
    trimmedList.forEach((npi) => {
      const isValidNpi = Utils.validateNPI(npi);

      if (isValidNpi && !valid.includes(npi)) {
        valid.push(npi);
      } else if (!isValidNpi && !invalid.includes(npi)) {
        invalid.push(npi);
      }
    });

    // Add valid NPIs to validNPIs state
    setValidNPIs(valid);
    // Add invalid NPIs to invalidNPIs state
    setInvalidNPIs(invalid);

    if (invalid.length) {
      setFormStep(1);
      return false;
    } else {
      // Go ahead and execute the mutation if there are no invalid npis
      executeMutation(valid);
    }

    return true;
  };

  const executeMutation = (npis) => {
    mutation.mutate({ npis });
    closeForm();
  };

  const closeForm = () => {
    setError(null);
    navigate(`/config/${organizationId}/managed-providers/aliases`);
  };

  return (
    <div style={{ position: "relative", padding: "30px 24px" }}>
      <Grid justify="center">
        <GridColumn>
          <FormContainer
            style={{
              paddingTop: "100px",
              minWidth: "500px",
            }}
          >
            <CloseButton onClose={closeForm} />

            {formStep === 0 && (
              <>
                <Typography variant="h2">Remove NPI Aliases</Typography>

                <Typography variant="p">
                  Enter the list of NPIs you wish to remove as Managed Provider
                  Aliases.
                </Typography>

                {error && <Error>{error}</Error>}

                <BasicNPIForm
                  onSubmit={checkNPIs}
                  onCancel={closeForm}
                  defaultValues={{
                    npis: defaultNPIs,
                  }}
                />
              </>
            )}
            {formStep === 1 && (
              <NPIValidationForm
                invalidNPIs={invalidNPIs}
                validNPIs={validNPIs}
                onCancel={closeForm}
                onSubmit={(correctedNPIs) =>
                  executeMutation(validNPIs.concat(correctedNPIs))
                }
                isSubmitting={mutation.isPending}
              />
            )}
          </FormContainer>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default RemoveAliases;
