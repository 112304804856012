import { Duration } from "luxon";

import useAuth from "core/hooks/useAuth";
import Modal from "core/components/Modal";
import Button from "core/components/Button";

const AuthExpireModal = () => {
  const { isNearExpired, resumeSession, logout, timeRemaining } = useAuth();

  return (
    <Modal visible={isNearExpired} dismiss={logout}>
      <div
        style={{
          color: "#3E3E3C",
          fontSize: "20px",
          letterSpacing: 0,
          lineHeight: "25px",
          textAlign: "center",
          paddingBottom: "34px",
        }}
      >
        You will be logged out in <br />
        {Duration.fromMillis(timeRemaining)
          .shiftTo("minutes", "seconds")
          .normalize()
          .toHuman()}
        .
      </div>
      <div>
        <Button onClick={resumeSession}>Resume Session</Button>
      </div>
    </Modal>
  );
};

export default AuthExpireModal;
