import { useCallback, useEffect } from "react";
import jwtDecode from "jwt-decode";

import { ROLES } from "core/constants";

import useCurrentUser from "./useCurrentUser";
import useLocalStore from "./useLocalStore";
import { DURATION_25_MIN } from "common/utilities";
import { usePostHog } from "posthog-js/react";

const PERMISSIONS = {
  [ROLES.systemAdmin]: [
    "admin:read",
    "org-management:read",
    "org-management:write",
    "org-management:switch",
    "user-management:read",
    "user-management:write",
    "configurations:read",
    "configurations:write",
  ],
  [ROLES.systemViewer]: [
    "admin:read",
    "org-management:read",
    "org-management:switch",
    "org-management:write",
    "user-management:read",
    "configurations:read",
    "user-management:write",
    {
      name: "user-management:write",
      rule: ({ userEmail, paramEmail }) => userEmail === paramEmail,
    },
  ],
  [ROLES.orgAdmin]: [
    "admin:read",
    "user-management:read",
    "user-management:write",
    "configurations:read",
    "configurations:write",
    "org-management:write",
  ],
  [ROLES.orgUser]: [
    "configurations:read",
    {
      name: "user-management:write",
      rule: ({ userEmail, paramEmail }) => userEmail === paramEmail,
    },
  ],
};

const useAuthStateChanged = () => {
  const posthog = usePostHog();
  const localStore = useLocalStore();
  const {
    currentUser,
    setCurrentUser,
    initializing,
    setInitializing,
    isIdentified,
    setIsIdentified,
  } = useCurrentUser();
  const userExists = Boolean(currentUser);
  useEffect(() => {
    if (posthog && currentUser?.user_id && !isIdentified) {
      // Identify logged in user
      posthog.identify(currentUser.user_id, {
        userId: currentUser.user_id,
        email: currentUser.email,
        name: currentUser.name,
        organizationName: currentUser.orgName,
        organizationId: currentUser.organizationId,
        role: currentUser.role,
      });
      setIsIdentified(true);
    }

    if (posthog && !currentUser && isIdentified) {
      // Identify logged in user
      posthog.reset();
      setIsIdentified(false);
    }
  }, [posthog, currentUser, isIdentified, setIsIdentified]);

  const watchLocalStorage = useCallback(
    (event) => {
      const {
        AUTH_TOKEN,
        REFRESH_TOKEN,
        EXPIRATION_TIME,
        LAST_ACTIVE_TIME,
        USER_ROLE,
        ORG_ID,
        NAME,
        ORG_NAME,
        PASSWORD_META,
        IS_PASSWORD_EXPIRING,
      } = localStore.getStore();

      if (!userExists) {
        // User is not in memory, but there is user data in local storage
        // (Most likely the user just opened the app)
        if (AUTH_TOKEN && USER_ROLE && ORG_ID && LAST_ACTIVE_TIME) {
          const now = Date.now();
          const nearExpiredTime = Number(LAST_ACTIVE_TIME) + DURATION_25_MIN;
          const isExpired = now >= nearExpiredTime;

          if (isExpired) {
            // Session is expired :(
            // Lets empty the user data in local storage.
            setCurrentUser(null);
            localStore.deleteKey(localStore.KEYS.AUTH_TOKEN);
            localStore.deleteKey(localStore.KEYS.REFRESH_TOKEN);
            localStore.deleteKey(localStore.KEYS.EXPIRATION_TIME);
            localStore.deleteKey(localStore.KEYS.LAST_ACTIVE_TIME);
            localStore.deleteKey(localStore.KEYS.USER_ROLE);
            localStore.deleteKey(localStore.KEYS.ORG_ID);
            localStore.deleteKey(localStore.KEYS.NAME);
            localStore.deleteKey(localStore.KEYS.ORG_NAME);
            localStore.deleteKey(localStore.KEYS.PASSWORD_META);
            localStore.deleteKey(localStore.KEYS.IS_PASSWORD_EXPIRING);
          } else {
            // Congrats! Its a valid user session.
            // Lets use it :)
            const userFromStorage = {
              ...jwtDecode(AUTH_TOKEN),
              accessToken: AUTH_TOKEN,
              refreshToken: REFRESH_TOKEN,
              expirationTime: Number(EXPIRATION_TIME),
              lastActiveTime: Number(LAST_ACTIVE_TIME),
              role: USER_ROLE,
              organizationId: ORG_ID,
              permissions: PERMISSIONS[USER_ROLE],
              name: NAME,
              orgName: ORG_NAME,
              passwordMeta: PASSWORD_META ? JSON.parse(PASSWORD_META) : null,
              isPasswordExpiring: IS_PASSWORD_EXPIRING === "true",
            };
            setCurrentUser(userFromStorage);
          }
        }

        // All done initializing.
        // Lets show the login screen,
        //  or the requested route if they had a valid user session.
        setInitializing(false);
      } else if (userExists && !AUTH_TOKEN && !USER_ROLE && !ORG_ID) {
        // User is in memory, but the local storage has no user data
        // (the user probably just logged out)
        setInitializing(true);
        setCurrentUser(null);
      } else if (initializing) {
        // No user defined and no user data in local storage.
        // Lets show the login screen.
        setInitializing(false);
      }
    },
    [localStore, userExists, initializing, setInitializing, setCurrentUser]
  );

  useEffect(() => {
    window.addEventListener("storage", watchLocalStorage);

    return () => window.removeEventListener("storage", watchLocalStorage);
  }, [watchLocalStorage]);

  useEffect(() => {
    if (initializing) {
      window.dispatchEvent(new Event("storage"));
    }
  }, [initializing]);

  useEffect(() => {
    if (!initializing) {
      window.dispatchEvent(new Event("storage"));
    }
  }, [initializing]);

  return { initializing, user: currentUser };
};

export default useAuthStateChanged;
