import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";

import {
  DURATION_1_SECOND,
  DURATION_25_MIN,
  DURATION_30_MIN,
} from "common/utilities";
import { ROLES } from "core/constants";
import { submitLogin, refreshAccessToken } from "modules/auth/actions";

import useCurrentUser from "./useCurrentUser";
import useLocalStore from "./useLocalStore";
import { getOrganizations, getUsers } from "modules/admin/actions";
import { useNavigate } from "react-router-dom";
import useFlash from "./useFlash";

const useAuth = () => {
  const { currentUser, setCurrentUser, setIsPasswordExpired } =
    useCurrentUser();
  const { setSuccess, setError } = useFlash();
  const localStore = useLocalStore();
  const navigate = useNavigate();

  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isNearExpired, setIsNearExpired] = useState(false);
  const [isResuming, setIsResuming] = useState(false);

  const login = useCallback(
    async ({ email, password }) => {
      setSuccess(null);
      setError(null);

      const now = Date.now();

      // Submit login
      try {
        const result = await submitLogin({
          email,
          password,
        });

        const accessToken = result.accessToken;
        const refreshToken = result.refreshToken;
        const expirationTime = now + DURATION_30_MIN;
        const userRole = ROLES[result.role];
        const orgId = result.organizationId;

        // Check if password is expiring
        const passwordMeta = result.passwordMeta;
        const passwordLastUpdated = DateTime.fromMillis(
          passwordMeta.lastUpdated
        );
        const daysSincePasswordLastUpdated = DateTime.now()
          .diff(passwordLastUpdated, "days")
          .toObject().days;
        const daysRemaining =
          passwordMeta.expirationPeriod - daysSincePasswordLastUpdated;
        const isPasswordExpiring =
          daysRemaining <= passwordMeta.updateReminderPeriod;

        // Get org name and user name
        const usersPromise = getUsers({
          accessToken,
          organizationId: orgId,
          email,
        });
        const orgsPromise = getOrganizations({
          accessToken,
          organizationId: orgId,
        });

        const [users, orgs] = await Promise.all([usersPromise, orgsPromise]);
        const name = users[0]?.name || "";
        const orgName = orgs[0]?.name;

        localStore.setKey(localStore.KEYS.AUTH_TOKEN, accessToken);
        localStore.setKey(localStore.KEYS.REFRESH_TOKEN, refreshToken);
        localStore.setKey(localStore.KEYS.EXPIRATION_TIME, expirationTime);
        localStore.setKey(localStore.KEYS.LAST_ACTIVE_TIME, now);
        localStore.setKey(localStore.KEYS.USER_ROLE, userRole);
        localStore.setKey(localStore.KEYS.ORG_ID, orgId);
        localStore.setKey(localStore.KEYS.NAME, name);
        localStore.setKey(localStore.KEYS.ORG_NAME, orgName);
        localStore.setKey(
          localStore.KEYS.PASSWORD_META,
          JSON.stringify(passwordMeta)
        );
        localStore.setKey(
          localStore.KEYS.IS_PASSWORD_EXPIRING,
          isPasswordExpiring
        );
      } catch (error) {
        if (error.resetLink) {
          setError(error.message);
          const url = new URL(error.resetLink);
          navigate(`${url.pathname}${url.search}`, { replace: true });
          setIsPasswordExpired(true);
        } else {
          throw error;
        }
      }
    },
    [localStore, navigate, setError, setSuccess, setIsPasswordExpired]
  );

  const logout = useCallback(
    (options = {}) => {
      const { redirectToLogin = true } = options;

      localStore.deleteKey(localStore.KEYS.AUTH_TOKEN);
      localStore.deleteKey(localStore.KEYS.REFRESH_TOKEN);
      localStore.deleteKey(localStore.KEYS.EXPIRATION_TIME);
      localStore.deleteKey(localStore.KEYS.LAST_ACTIVE_TIME);
      localStore.deleteKey(localStore.KEYS.USER_ROLE);
      localStore.deleteKey(localStore.KEYS.ORG_ID);
      localStore.deleteKey(localStore.KEYS.NAME);
      localStore.deleteKey(localStore.KEYS.ORG_NAME);
      localStore.deleteKey(localStore.KEYS.PASSWORD_META);
      localStore.deleteKey(localStore.KEYS.IS_PASSWORD_EXPIRING);

      if (redirectToLogin) {
        navigate("/login", { replace: true });
      }

      setCurrentUser(null);
    },
    [localStore, navigate, setCurrentUser]
  );

  const refresh = useCallback(async () => {
    const now = Date.now();
    const expirationTime = now + DURATION_30_MIN;

    console.log("refresh, expires at ", new Date(expirationTime));

    const result = await refreshAccessToken({
      refreshToken: currentUser.refreshToken,
    });
    const accessToken = result.access_token;
    const refreshToken = result.refresh_token;

    localStore.setKey(localStore.KEYS.AUTH_TOKEN, accessToken);
    localStore.setKey(localStore.KEYS.REFRESH_TOKEN, refreshToken);
    localStore.setKey(localStore.KEYS.EXPIRATION_TIME, expirationTime);
    localStore.setKey(localStore.KEYS.LAST_ACTIVE_TIME, now);

    const user = {
      ...currentUser,
      accessToken,
      refreshToken,
      expirationTime,
      lastActiveTime: now,
    };

    setCurrentUser(user);

    return user;
  }, [currentUser, localStore, setCurrentUser]);

  const activeNow = useCallback(async () => {
    const now = Date.now();

    // console.log("active now, expires at ", new Date(now + DURATION_30_MIN));

    localStore.setKey(localStore.KEYS.LAST_ACTIVE_TIME, now);
    setCurrentUser({
      ...currentUser,
      lastActiveTime: now,
    });
  }, [currentUser, setCurrentUser, localStore]);

  const resumeSession = useCallback(async () => {
    if (isResuming) return;

    setIsResuming(true);
    setIsNearExpired(false);

    await refresh();

    setIsResuming(false);
  }, [isResuming, refresh]);

  const handleExpire = useCallback(() => {
    if (!currentUser?.lastActiveTime) return;

    const now = Date.now();
    const expiredTime = currentUser.lastActiveTime + DURATION_30_MIN;
    const nearExpiredTime = currentUser.lastActiveTime + DURATION_25_MIN;

    const nowIsExpired = now >= expiredTime;
    const nowIsNearExpired = now >= nearExpiredTime;

    if (isResuming) {
      // console.log("is resuming");

      setIsNearExpired(false);
      setTimeRemaining(0);
    } else if (nowIsExpired) {
      console.log("is expired");

      setIsNearExpired(false);
      setTimeRemaining(0);
      logout();
    } else if (nowIsNearExpired) {
      setIsNearExpired(true);
      setTimeRemaining(Math.ceil((expiredTime - now) / 1000) * 1000);
    } else if (isNearExpired) {
      setIsNearExpired(false);
      setTimeRemaining(0);
    }
  }, [currentUser?.lastActiveTime, isResuming, isNearExpired, logout]);

  useEffect(() => {
    const id = setInterval(handleExpire, DURATION_1_SECOND);
    return () => clearInterval(id);
  }, [handleExpire]);

  return {
    isNearExpired,
    timeRemaining,

    login,
    logout,
    refresh,
    resumeSession,
    activeNow,
  };
};

export default useAuth;
