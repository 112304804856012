import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";
import {
  validate,
  required,
  password,
  newAndConfirmPasswordsMatch,
  newAndConfirmPasswordsMatchError,
} from "common/validation";
import Error from "core/components/Error";

const CreatePasswordForm = ({
  onSubmit,
  defaultValues,
  error,
  isPasswordExpired,
}) => {
  return (
    <Form
      mode="create"
      onSubmit={onSubmit}
      submitLabel={isPasswordExpired ? "Update Password" : "Create Password"}
      defaultValues={defaultValues}
      validate={async (values, form) => {
        // wait until next frame or else this loops infinitely
        await new Promise((resolve) => setTimeout(() => resolve()));

        const confirmPasswordFieldMeta = form.getFieldMeta("confirmPassword");

        // error if newPassword !== confirmPassword
        if (
          values.newPassword &&
          values.confirmPassword &&
          values.newPassword !== values.confirmPassword
        ) {
          form.setFieldMeta("confirmPassword", {
            error: newAndConfirmPasswordsMatchError,
          });
        } else if (
          confirmPasswordFieldMeta &&
          confirmPasswordFieldMeta.error === newAndConfirmPasswordsMatchError
        ) {
          form.setFieldMeta("confirmPassword", { error: null });
        }

        return undefined;
      }}
    >
      <Error>{error}</Error>
      <Input
        type="password"
        field="newPassword"
        label={isPasswordExpired ? "New Password" : "Create Password"}
        autoComplete="new-password"
        validate={validate(required(), password())}
      />
      <Input
        type="password"
        field="confirmPassword"
        label={isPasswordExpired ? "Confirm New Password" : "Confirm Password"}
        autoComplete="new-password"
        validate={validate(
          required(),
          password(),
          newAndConfirmPasswordsMatch()
        )}
      />
    </Form>
  );
};

export default CreatePasswordForm;
