import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";
import {
  validate,
  required,
  password,
  doesNotMatchCurrentPasswordError,
  newAndConfirmPasswordsMatchError,
  doesNotMatchCurrentPassword,
  newAndConfirmPasswordsMatch,
} from "common/validation";
import Error from "core/components/Error";

const ExpiringPasswordForm = ({ onSubmit, defaultValues, error }) => {
  return (
    <Form
      mode="create"
      onSubmit={onSubmit}
      submitLabel="Update Password"
      defaultValues={defaultValues}
      validate={async (values, form) => {
        // wait until next frame or else this loops infinitely
        await new Promise((resolve) => setTimeout(() => resolve()));

        const newPasswordFieldMeta = form.getFieldMeta("newPassword");
        const newPasswordConfirmFieldMeta =
          form.getFieldMeta("newPasswordConfirm");

        // error if oldPassword === newPassword
        if (
          values.oldPassword &&
          values.newPassword &&
          values.oldPassword === values.newPassword
        ) {
          form.setFieldMeta("newPassword", {
            error: doesNotMatchCurrentPasswordError,
          });
        } else if (
          newPasswordFieldMeta &&
          newPasswordFieldMeta.error === doesNotMatchCurrentPasswordError
        ) {
          form.setFieldMeta("newPassword", { error: null });
        }

        // error if newPassword !== newPasswordConfirm
        if (
          values.newPassword &&
          values.newPasswordConfirm &&
          values.newPassword !== values.newPasswordConfirm
        ) {
          form.setFieldMeta("newPasswordConfirm", {
            error: newAndConfirmPasswordsMatchError,
          });
        } else if (
          newPasswordConfirmFieldMeta &&
          newPasswordConfirmFieldMeta.error === newAndConfirmPasswordsMatchError
        ) {
          form.setFieldMeta("newPasswordConfirm", { error: null });
        }

        return undefined;
      }}
    >
      <Error>{error}</Error>
      <Input
        type="password"
        field="oldPassword"
        label="Current Password"
        validate={validate(required(), password())}
      />
      <Input
        type="password"
        field="newPassword"
        label="New Password"
        autoComplete="new-password"
        validate={validate(
          required(),
          password(),
          doesNotMatchCurrentPassword()
        )}
      />
      <Input
        type="password"
        field="newPasswordConfirm"
        label="Confirm New Password"
        autoComplete="new-password"
        validate={validate(
          required(),
          password(),
          newAndConfirmPasswordsMatch()
        )}
      />
    </Form>
  );
};

export default ExpiringPasswordForm;
