import useTheme from "core/hooks/useTheme";
import { Page as StyledPage } from "../styles";

const Page = ({ showBackground = true, children }) => {
  const { theme } = useTheme();
  const pageStyle = {
    backgroundColor: showBackground ? theme.app.backgroundColor : null,
    justifyContent: children.length > 1 ? "space-between" : "center",
  };

  return (
    <StyledPage style={pageStyle} $showBackground={showBackground}>
      <div style={{ zIndex: "20", flex: "1", overflow: "auto" }}>
        {children}
      </div>
    </StyledPage>
  );
};

export default Page;
