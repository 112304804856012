import { DURATION_25_MIN, DURATION_5_MIN } from "common/utilities";
import useAuth from "./useAuth";
import useCurrentUser from "./useCurrentUser";

// A wrapper for the the function passed to useQuery
// Provides currentUser's accessToken to the underlying api call invoked
// Also keeps the react-query devtools from being polluted with long token strings
const useAuthenticatedCall = (apiCall) => {
  const { refresh, logout, activeNow } = useAuth();
  const { currentUser } = useCurrentUser();

  let { lastActiveTime, accessToken, expirationTime } = currentUser;

  return (queryArguments) => {
    const {
      // eslint-disable-next-line no-unused-vars
      queryKey: [_key, organizationId, requestParams],
      pageParam,
      meta,
    } = queryArguments;

    const now = Date.now();

    activeNow();

    if (
      expirationTime < now + DURATION_5_MIN && // expires in next 5 min
      lastActiveTime > now - DURATION_25_MIN // active in prev 25 min
    ) {
      return refresh().then(
        (result) => {
          accessToken = result.accessToken;
          expirationTime = result.expirationTime;

          return apiCall({
            organizationId,
            ...requestParams,
            pageParam,
            meta,
            accessToken,
          });
        },
        (error) => logout()
      );
    }

    return apiCall({
      organizationId,
      ...requestParams,
      pageParam,
      meta,
      accessToken,
    });
  };
};

export default useAuthenticatedCall;
