import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Page from "core/components/Page";
import { passwordReset } from "modules/auth/actions";
import ResetPasswordForm from "modules/auth/ResetPasswordForm";
import useFlash from "core/hooks/useFlash";
import useAuth from "core/hooks/useAuth";
import CopyButton from "core/components/CopyButton";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const defaultValues = {
  email: "",
};

const ForgotPassword = () => {
  const { setSuccess } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const [error, setError] = useState("");
  const { logout } = useAuth();

  const handleSubmit = async (values) => {
    setError("");

    try {
      const result = await passwordReset({ ...values, origin });

      logout({ redirectToLogin: false });
      setSuccess(result.message || "Success");
      navigate("/login");
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Page>
      <Grid columns="350px" justify="center" lift="15vh">
        <GridColumn>
          <Typography variant="h1">Reset Password</Typography>
          <Typography variant="p">
            Enter the email associated with your account to receive an email
            with a password reset link.
          </Typography>

          <ResetPasswordForm
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={error}
          />

          <Typography
            variant="p"
            style={{
              textAlign: "left",
              marginBottom: "0px",
              marginTop: "-5px",
            }}
          >
            Having trouble? Contact{" "}
            <a href="mailto:support@populi.ai">support@populi.ai</a>
            <CopyButton value="support@populi.ai" hoverTitle="Copy Link" />
          </Typography>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default ForgotPassword;
