import { useState } from "react";
import UserContext from "./userContext";

const UserProvider = ({ children, settings }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [isIdentified, setIsIdentified] = useState(false);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isPasswordExpired,
        setIsPasswordExpired,
        initializing,
        setInitializing,
        isIdentified,
        setIsIdentified,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
