import { useLocation, Navigate, matchPath, useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import ProtectedLayout from "./ProtectedLayout";
import PageNotFound from "pages/404";
import useAuthStateChanged from "core/hooks/useAuthStateChanged";
import useHasPermissions from "core/hooks/useHasPermissions";
import Loader from "core/components/Loader";
import { useEffect, useRef } from "react";

const protectedRoutes = [
  { to: "/" },
  { to: "/404" },
  { to: "/admin", permissions: "admin:read" },
  {
    to: "/admin/switch-org",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/switch-org/:orgId",
    permissions: "org-management:switch",
  },
  {
    to: "/admin/org-management",
    permissions: "org-management:read",
  },
  {
    to: "/admin/org-management/create",
    permissions: "org-management:write",
  },
  {
    to: "/admin/org-management/:orgId",
    permissions: "user-management:read",
  },
  {
    to: "/admin/org-management/:organizationId/users/create",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:organizationId/users/:email/profile/*",
    permissions: "user-management:write",
  },
  {
    to: "/admin/org-management/:orgId/update/*",
    permissions: "org-management:write",
  },
  {
    to: "/config/:orgId/managed-providers",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/aliases",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships",
    permissions: "configurations:read",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/relationships/remove/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/update/:npi",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove",
    permissions: "configurations:write",
  },
  {
    to: "/config/:orgId/managed-providers/aliases/remove/:npi",
    permissions: "configurations:write",
  },
];

const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);
  const { user } = useAuthStateChanged();

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname, resetErrorBoundary]);

  return (
    <ProtectedLayout
      loggedInUser={user}
      pageTitle="404"
      showBackLink={false}
      hideAppShell={false}
    >
      <PageNotFound hideGoBack={true} />
    </ProtectedLayout>
  );
};

const ProtectedRoute = ({
  pageTitle,
  backLinkText,
  backLinkTo,
  backLinkPermissions,
  hideAppShell = false,
  children,
}) => {
  const location = useLocation();
  const params = useParams();
  const { initializing, user } = useAuthStateChanged();
  const matchedRoute = protectedRoutes.find((route) =>
    matchPath(route.to, location.pathname)
  );

  const userIsAuthorized = useHasPermissions(
    user?.permissions,
    matchedRoute.permissions,
    { userEmail: user?.email, paramEmail: params?.email }
  );

  const showBackLink = useHasPermissions(
    user?.permissions,
    backLinkPermissions
  );

  if (initializing) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!userIsAuthorized) {
    return <Navigate to="/" replace />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <ProtectedLayout
        loggedInUser={user}
        pageTitle={pageTitle}
        backLinkText={backLinkText}
        backLinkTo={backLinkTo}
        showBackLink={showBackLink}
        hideAppShell={hideAppShell}
      >
        {children}
      </ProtectedLayout>
    </ErrorBoundary>
  );
};

export default ProtectedRoute;
