import { faEllipsis } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion, {
  AccordionContent,
  AccordionItem,
} from "core/components/Accordion";
import Button from "core/components/Button";
import Chip from "core/components/Chip";
import Modal from "core/components/Modal";
import Search from "core/components/Search";
import { Row } from "core/styles";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getComplexFieldValue } from "..";
import { DateTime } from "luxon";
import Skeleton from "react-loading-skeleton";
import ButtonLink from "core/components/ButtonLink";
import Typography from "core/components/Typography";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FieldError } from "core/components/Form/styles";
import Error from "core/components/Error";

const PRODUCT_BUNDLE_IDS = {
  POP_INTEL: "population_intelligence",
  PROV_INTEL: "network_intelligence",
  MARK_INTEL: "market_intelligence",
};

const PRODUCT_IDS = {
  IDENTIFIED_CONSUMERS: "identified_consumers",
  DE_IDENTIFIED_CONSUMERS: "deidentified_consumers",
  PROPENSITY_MODELS: "propensity_models",
  NEW_MOVERS: "new_movers",
  MARKET_VOLUME_LITE: "market_volume_lite",
  PROVIDER_AFFILIATIONS: "provider_affiliations",
  PROVIDER_PATHWAYS: "provider_pathways",
  PROVIDER_BENCHMARKS: "provider_benchmarks",
  MARKET_VOLUME: "market_volume",
  CLAIM_VOLUME: "claim_volume",
  PATIENT_VOLUME: "patient_volume",
  MARKET_VOLUME_ONCOLOGY: "market_volume_oncology",
  PRICE_TRANSPARENCY: "price_transparency",
};

const MAX_CHIPS = 5;

const API_DATE_FORMAT = "yyyy-MM-dd";

const ProductLabel = styled.p`
  display: block;
  color: #000000;
  font-family: "Inter";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0px;
`;

const SettingLabel = styled.p`
  display: block;
  margin: 0px;
  margin-bottom: 10px;
  color: #000000;
  font-family: "Inter";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
`;

const ChipContainer = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 7px;
`;

const QueryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 5px;
`;

const QueryRow = styled.div`
  display: flex;
  gap: 5px;
`;
const QueryChildren = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  gap: 5px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 552px;
  max-height: 280px;
  overflow: auto;
  border-top: solid 1px #dddbda;
  margin-bottom: 15px;
`;

const ListItem = styled.div`
  padding: 10px 20px;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  border-bottom: solid 1px #dddbda;
  ${(props) => props.index % 2 === 0 && "background-color: #F3F2F2;"}
`;

const SETTINGS = {
  ANONYMIZE_DATA: "anonymize-data",
  ATTRIBUTES: "attributes",
  CONSUMER_RECORDS: "consumer-records",
  RECORDS: "records",
  STATES: "states",
  ZIPCODES: "zipcodes",
  MODELS: "models",
  QUERY: "query",
  QUARTERS: "quarters",
  MONTHS: "months",
  YEARS: "years",
};

export const ModalHeader = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0px;
  line-height: 1;
  padding-bottom: 15px;
`;

const Rule = ({ rule, showMoreItems, isGroup = false, isLast }) => {
  if (rule.operator === "between") {
    return (
      <QueryRow>
        <Chip
          text={`${rule.field} ${rule.operator} ${rule.from} and ${rule.to}`}
        />
        {isGroup && isLast && <span>)</span>}
      </QueryRow>
    );
  } else if (rule.operator === "is in") {
    const items = Array.isArray(rule.value)
      ? rule.value
      : rule.value.split(/\s*,\s*/);
    const hasMore = items.length > MAX_CHIPS;

    return (
      <QueryRow>
        <Chip
          text={
            <>
              {rule.field} {rule.operator}&nbsp;
              {items.slice(0, MAX_CHIPS).join(", ")}
              {hasMore && (
                <>
                  &nbsp; &nbsp;
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    size="xl"
                    onClick={() =>
                      showMoreItems(
                        rule.field,
                        items.map((item) => ({ label: item }))
                      )
                    }
                    style={{ color: "#000000", cursor: "pointer" }}
                  />
                </>
              )}
            </>
          }
        />
        {isGroup && isLast && <span>)</span>}
      </QueryRow>
    );
  } else {
    return (
      <QueryRow>
        <Chip text={`${rule.field} ${rule.operator} ${rule.value}`} />
        {isGroup && isLast && <span>)</span>}
      </QueryRow>
    );
  }
};

const Group = ({ group, showMoreItems }) => (
  <>
    <QueryRow>
      <Chip text={group.operator || "and"} />
      <span>(</span>
    </QueryRow>
    <QueryChildren>
      <QueryItems
        items={group.filters}
        showMoreItems={showMoreItems}
        isGroup={true}
      />
    </QueryChildren>
  </>
);

const QueryItems = ({ items, showMoreItems, isGroup = false }) => (
  <>
    {items?.map((item, index) => (
      <QueryItem
        key={index}
        item={item}
        showMoreItems={showMoreItems}
        isGroup={isGroup}
        isLast={index === items.length - 1}
      />
    ))}
  </>
);

const QueryItem = ({ item, showMoreItems, isGroup, isLast }) =>
  item.filters ? (
    <Group group={item} showMoreItems={showMoreItems} />
  ) : (
    <Rule
      rule={item}
      showMoreItems={showMoreItems}
      isGroup={isGroup}
      isLast={isLast}
    />
  );

const COMPONENTS = {
  [SETTINGS.ANONYMIZE_DATA]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });

    return (
      <SettingLabel>
        Anonymize Data <b>{value ? "On" : "Off"}</b>
      </SettingLabel>
    );
  },
  [SETTINGS.ATTRIBUTES]: ({ product, reviewFieldKey, showMoreItems }) => {
    const [limitKey, selectedKey] = reviewFieldKey.split(",");
    const productLimitField = product.fields[limitKey];
    const productSelectedField = product.fields[selectedKey];

    const limitValue = getComplexFieldValue({
      dataId: productLimitField.dataId,
      data: product.data,
    });
    const selectedValue = getComplexFieldValue({
      dataId: productSelectedField.dataId,
      data: product.data,
    });
    const hasMore = selectedValue?.length > MAX_CHIPS;
    const error = [
      product.fieldErrors[limitKey],
      product.fieldErrors[selectedKey],
    ]
      .join(" ")
      .trim();

    return (
      <>
        <SettingLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <div>
              <b>{selectedValue?.length.toLocaleString() || 0}</b> Selected
              Attributes / <b>{Number(limitValue || 0).toLocaleString()}</b>{" "}
              Purchased Attributes
            </div>
            {error && (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ marginBottom: "2px", color: "#BA351C" }}
              />
            )}
          </div>
        </SettingLabel>

        {selectedValue && (
          <ChipContainer>
            {selectedValue.slice(0, MAX_CHIPS).map((attribute) => {
              return <Chip key={attribute} text={attribute} />;
            })}
            {hasMore && (
              <span onClick={() => showMoreItems("Attributes", selectedValue)}>
                <Chip
                  text="See All"
                  variant="outlined"
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
          </ChipContainer>
        )}
        {error && (
          <FieldError
            style={{
              textAlign: "left",
              marginTop: "-15px",
              marginBottom: "15px",
            }}
          >
            {error}
          </FieldError>
        )}
      </>
    );
  },
  [SETTINGS.CONSUMER_RECORDS]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });

    return (
      <SettingLabel>
        <b>{Number(value).toLocaleString()}</b> Consumer Records
      </SettingLabel>
    );
  },
  [SETTINGS.RECORDS]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });

    return (
      <SettingLabel>
        <b>{Number(value).toLocaleString()}</b> Records Limit
      </SettingLabel>
    );
  },
  [SETTINGS.STATES]: ({ product, reviewFieldKey, showMoreItems }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });
    const hasMore = value?.length > MAX_CHIPS;

    const control = useMemo(() => {
      return product.page.settings
        .find((s) => s.controls.find((c) => c.fieldKey === reviewFieldKey))
        .controls.find((c) => c.fieldKey === reviewFieldKey);
    }, [product.page.settings, reviewFieldKey]);

    const maxOptionsCount = control.dynamicProps.catalogFields.length;
    const showLengthOrAll = (selectedCount, maxCount) =>
      selectedCount === maxCount ? "All" : selectedCount;
    const isStateFilteringRequired = product.isStateFilteringRequired;
    const error = product.fieldErrors[reviewFieldKey];

    return (
      <>
        <SettingLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <div>
              {isStateFilteringRequired ? (
                <b>
                  {value?.length
                    ? showLengthOrAll(value.length, maxOptionsCount)
                    : 0}
                </b>
              ) : (
                <b>{!value ? "All" : value.length}</b>
              )}{" "}
              States Selected
            </div>
            {error && (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ marginBottom: "2px", color: "#BA351C" }}
              />
            )}
          </div>
        </SettingLabel>

        {value?.length ? (
          <ChipContainer>
            {value.slice(0, MAX_CHIPS).map((item) => {
              return <Chip text={item} key={item} />;
            })}
            {hasMore && (
              <span onClick={() => showMoreItems("States", value)}>
                <Chip
                  text="See All"
                  variant="outlined"
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
          </ChipContainer>
        ) : null}

        {error && (
          <FieldError
            style={{
              textAlign: "left",
              marginBottom: "15px",
            }}
          >
            {error}
          </FieldError>
        )}
      </>
    );
  },
  [SETTINGS.ZIPCODES]: ({ product, reviewFieldKey, showMoreItems }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });
    const hasMore = value?.length > MAX_CHIPS;

    return (
      <>
        <SettingLabel>
          <b>{value?.length || 0}</b> Selected Zip Codes
        </SettingLabel>
        {value?.length ? (
          <ChipContainer>
            {value.slice(0, MAX_CHIPS).map((item) => {
              return <Chip text={item} key={item} />;
            })}
            {hasMore && (
              <span
                onClick={() =>
                  showMoreItems(
                    "Zip codes",
                    value.map((zip) => ({ label: zip }))
                  )
                }
              >
                <Chip
                  text="See All"
                  variant="outlined"
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
          </ChipContainer>
        ) : null}
      </>
    );
  },
  [SETTINGS.MODELS]: ({ product, reviewFieldKey, showMoreItems }) => {
    const [limitKey, selectedKey] = reviewFieldKey.split(",");
    const productLimitField = product.fields[limitKey];
    const productSelectedField = product.fields[selectedKey];

    const limitValue = getComplexFieldValue({
      dataId: productLimitField.dataId,
      data: product.data,
    });
    const selectedValue = getComplexFieldValue({
      dataId: productSelectedField.dataId,
      data: product.data,
    });
    const hasMore = selectedValue?.length > MAX_CHIPS;

    return (
      <>
        <SettingLabel>
          <b>{selectedValue?.length || 0}</b> Selected Models /{" "}
          <b>{Number(limitValue).toLocaleString()}</b> Purchased Models
        </SettingLabel>
        {selectedValue && (
          <ChipContainer>
            {selectedValue.slice(0, MAX_CHIPS).map((item) => {
              return <Chip text={item.label} key={item.label} />;
            })}
            {hasMore && (
              <span onClick={() => showMoreItems("Models", selectedValue)}>
                <Chip
                  text="See All"
                  variant="outlined"
                  style={{ cursor: "pointer" }}
                />
              </span>
            )}
          </ChipContainer>
        )}
      </>
    );
  },
  [SETTINGS.QUARTERS]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });
    const error = product.fieldErrors[reviewFieldKey];

    return (
      <>
        <SettingLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <div>
              {value ? (
                <>
                  Quarters{" "}
                  <b>
                    {DateTime.fromFormat(value.from, API_DATE_FORMAT).toFormat(
                      "qq-yyyy"
                    )}
                  </b>{" "}
                  to{" "}
                  <b>
                    {DateTime.fromFormat(value.to, API_DATE_FORMAT).toFormat(
                      "qq-yyyy"
                    )}
                  </b>{" "}
                  Selected
                </>
              ) : (
                "No quarters selected"
              )}
            </div>
            {error && (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ marginBottom: "2px", color: "#BA351C" }}
              />
            )}
          </div>
        </SettingLabel>
        {error && (
          <FieldError
            style={{
              textAlign: "left",
              marginBottom: "15px",
            }}
          >
            {error}
          </FieldError>
        )}
      </>
    );
  },
  [SETTINGS.MONTHS]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });
    const error = product.fieldErrors[reviewFieldKey];

    return (
      <>
        <SettingLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <div>
              {value ? (
                <>
                  Months{" "}
                  <b>
                    {DateTime.fromFormat(value.from, API_DATE_FORMAT).toFormat(
                      "MM-yyyy"
                    )}
                  </b>{" "}
                  to{" "}
                  <b>
                    {DateTime.fromFormat(value.to, API_DATE_FORMAT).toFormat(
                      "MM-yyyy"
                    )}
                  </b>{" "}
                  Selected
                </>
              ) : (
                "No months selected"
              )}
            </div>
            {error && (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ marginBottom: "2px", color: "#BA351C" }}
              />
            )}
          </div>
        </SettingLabel>
        {error && (
          <FieldError
            style={{
              textAlign: "left",
              marginBottom: "15px",
            }}
          >
            {error}
          </FieldError>
        )}
      </>
    );
  },
  [SETTINGS.YEARS]: ({ product, reviewFieldKey }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });
    const error = product.fieldErrors[reviewFieldKey];

    return (
      <>
        <SettingLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <div>
              {value ? (
                <>
                  Years{" "}
                  <b>
                    {DateTime.fromFormat(value.from, API_DATE_FORMAT).toFormat(
                      "yyyy"
                    )}
                  </b>{" "}
                  to{" "}
                  <b>
                    {DateTime.fromFormat(value.from, API_DATE_FORMAT).toFormat(
                      "yyyy"
                    )}
                  </b>{" "}
                  Selected
                </>
              ) : (
                "No years selected"
              )}
            </div>
            {error && (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ marginBottom: "2px", color: "#BA351C" }}
              />
            )}
          </div>
        </SettingLabel>
        {error && (
          <FieldError
            style={{
              textAlign: "left",
              marginBottom: "15px",
            }}
          >
            {error}
          </FieldError>
        )}
      </>
    );
  },
  [SETTINGS.QUERY]: ({ product, reviewFieldKey, showMoreItems }) => {
    const productField = product.fields[reviewFieldKey];
    const value = getComplexFieldValue({
      dataId: productField.dataId,
      data: product.data,
    });

    return (
      <>
        <SettingLabel>Filters</SettingLabel>
        {value?.length ? (
          <QueryContainer>
            <QueryItems items={value} showMoreItems={showMoreItems} />
          </QueryContainer>
        ) : (
          <Typography variant="p" noMargin>
            No filters defined.
          </Typography>
        )}
      </>
    );
  },
};

const FIELDS = {
  [PRODUCT_BUNDLE_IDS.POP_INTEL]: {
    [PRODUCT_IDS.IDENTIFIED_CONSUMERS]: {
      [SETTINGS.ANONYMIZE_DATA]: "isAnonymized",
      [SETTINGS.ATTRIBUTES]: "attributeLimit,purchasedAttributes",
      [SETTINGS.CONSUMER_RECORDS]: "recordLimit",
      [SETTINGS.STATES]: "states",
      [SETTINGS.ZIPCODES]: "zipcodes",
      [SETTINGS.QUERY]: "consumerFilters",
    },
    [PRODUCT_IDS.DE_IDENTIFIED_CONSUMERS]: {
      [SETTINGS.STATES]: "states",
    },
    [PRODUCT_IDS.PROPENSITY_MODELS]: {
      [SETTINGS.MODELS]: "modelLimit,selectedModels",
    },
    [PRODUCT_IDS.NEW_MOVERS]: {
      [SETTINGS.RECORDS]: "recordLimit",
      [SETTINGS.STATES]: "states",
      [SETTINGS.QUERY]: "moverFilters",
    },
    [PRODUCT_IDS.MARKET_VOLUME_LITE]: {
      [SETTINGS.MONTHS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
  },
  [PRODUCT_BUNDLE_IDS.PROV_INTEL]: {
    [PRODUCT_IDS.PROVIDER_AFFILIATIONS]: {
      [SETTINGS.QUARTERS]: "licensedDates",
    },
    [PRODUCT_IDS.PROVIDER_PATHWAYS]: {
      [SETTINGS.MONTHS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
    [PRODUCT_IDS.PROVIDER_BENCHMARKS]: {
      [SETTINGS.QUARTERS]: "licensedDates",
    },
    [PRODUCT_IDS.MARKET_VOLUME]: {
      [SETTINGS.MONTHS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
  },
  [PRODUCT_BUNDLE_IDS.MARK_INTEL]: {
    [PRODUCT_IDS.CLAIM_VOLUME]: {
      [SETTINGS.MONTHS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
    [PRODUCT_IDS.PATIENT_VOLUME]: {
      [SETTINGS.YEARS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
    [PRODUCT_IDS.MARKET_VOLUME_ONCOLOGY]: {
      [SETTINGS.MONTHS]: "licensedDates",
      [SETTINGS.STATES]: "states",
    },
    [PRODUCT_IDS.PRICE_TRANSPARENCY]: {
      [SETTINGS.QUARTERS]: "licensedDates",
    },
  },
};

const SkeletonAccordion = ({ itemCount = 1 }) => {
  return (
    <Skeleton
      count={itemCount}
      style={{
        height: "58px",
        padding: "15px 20px",
        marginBottom: "10px",
        borderRadius: "5px",
      }}
    />
  );
};

const CustomHeaderTitle = ({ title, bundleHasError = false }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        textAlign: "left",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        className="state-observer"
        style={{
          position: "absolute",
          height: "1px",
          bottom: "-12px",
          width: "100%",
          backgroundColor: "#DBDBDB",
          zIndex: 100,
        }}
      />
      <div>{title}</div>
      {bundleHasError && (
        <div>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            size="lg"
            style={{ marginBottom: "-2px", color: "#BA351C" }}
          />
        </div>
      )}
    </div>
  );
};

const ContentContainer = styled.div`
  padding-left: 30px;

  &:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    margin-top: 8px;
    z-index: 100;
    background-color: #dbdbdb;
  }
`;

const DeploymentReview = ({
  bundles,
  products,
  productsEnabled,
  getDynamicProduct,
  handleSubmit,
  isSubmitting,
  isDisabled,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [moreTitle, setMoreTitle] = useState(null);
  const [moreItems, setMoreItems] = useState(null);
  const [moreSearch, setMoreSearch] = useState("");
  const [openItems, setOpenItems] = useState([]);

  const modalItems = useMemo(() => {
    return moreItems && moreSearch
      ? moreItems.filter((item) =>
          (item.label || item).toLowerCase().includes(moreSearch.toLowerCase())
        )
      : moreItems;
  }, [moreItems, moreSearch]);

  const formHasError = useMemo(() => {
    return Object.values(products).some((product) => product.hasError);
  }, [products]);

  const showMoreItems = (title, items) => {
    setMoreTitle(title);
    setMoreItems(items);
  };

  const hideMoreItems = () => {
    setMoreTitle(null);
    setMoreItems(null);
    setMoreSearch("");
  };

  if (isLoading) {
    return <SkeletonAccordion itemCount={bundles.length} />;
  }

  return (
    <div style={{ width: "650px" }}>
      {formHasError && (
        <Error style={{ width: "650px", marginBottom: "10px" }}>
          Resolve all errors before submitting form.
        </Error>
      )}
      <Accordion openItems={openItems} setOpenItems={setOpenItems}>
        {Object.values(bundles).map((bundle) => {
          const bundleProducts = Object.values(products).filter(
            (product) => product.meta.bundle.id === bundle.id
          );
          const bundleHasError = bundleProducts.some(
            (product) => product.hasError
          );

          return (
            <AccordionItem
              key={bundle.id}
              value={bundle.id}
              title={bundle.name}
              HeaderTitle={CustomHeaderTitle}
              customProps={{ bundleHasError }}
            >
              <AccordionContent>
                {bundleProducts.map((product) => {
                  const dynamicProduct = getDynamicProduct(product.id);
                  const reviewFields = FIELDS[bundle.id]?.[product.id];
                  const enabled = productsEnabled.includes(product.id);
                  const productLink = `../${bundle.path}/${product.page.path}`;

                  return (
                    <ContentContainer key={product.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          paddingTop: "3px",
                        }}
                      >
                        <ProductLabel>{product.meta.name}</ProductLabel>
                        <ButtonLink
                          to={productLink}
                          variant="text"
                          style={{ marginTop: "6px" }}
                        >
                          <Typography
                            variant="sub-text"
                            noMargin
                            style={{ color: "#007EAC" }}
                          >
                            Edit
                          </Typography>
                        </ButtonLink>
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                        }}
                      >
                        {enabled
                          ? reviewFields &&
                            Object.keys(reviewFields).map((key) => {
                              const Component = COMPONENTS[key];

                              return (
                                <div key={key}>
                                  <Component
                                    product={dynamicProduct}
                                    reviewFieldKey={reviewFields[key]}
                                    showMoreItems={showMoreItems}
                                  />
                                </div>
                              );
                            })
                          : "Not enabled."}
                        {}
                      </div>
                    </ContentContainer>
                  );
                })}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>

      <Row style={{ justifyContent: "flex-end", gap: "20px" }}>
        <Button
          variant="outlined"
          style={{ maxWidth: "fit-content" }}
          onClick={() => navigate("../", { state: { from: "route" } })}
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          width="200px"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          disabled={isDisabled || isSubmitting || formHasError}
        >
          Update Licenses
        </Button>
      </Row>

      <Modal visible={!!moreItems} dismiss={hideMoreItems}>
        <ModalHeader>{moreTitle}</ModalHeader>
        <Search
          styles={{ container: { width: "100%", marginBottom: "15px" } }}
          value={moreSearch}
          onChange={(e) => setMoreSearch(e.target.value)}
        />

        <List>
          {modalItems?.map((item, index) => {
            // Matt: need to make this consistent....not sure why zipcodes have label
            return (
              <ListItem key={index} index={index}>
                {item.label || item}
              </ListItem>
            );
          })}
        </List>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button variant="outlined" width="143px" onClick={hideMoreItems}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeploymentReview;
