import { createRoot } from "react-dom/client";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostHogProvider } from "posthog-js/react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./global-styles";
import UserProvider from "core/context/UserProvider";
import ToastProvider from "core/context/ToastProvider";
import FlashProvider from "core/context/FlashProvider";
import NavigationProvider from "core/context/NavigationProvider";
import ThemeProvider from "core/context/ThemeProvider";
import { Utils } from "common";

import "normalize.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import FeatureProvider from "core/context/FeatureProvider";
import { ENVIRONMENTS, getEnv } from "common/utilities";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const queryCache = new QueryCache({});
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      refetchOnMount: false,
    },
  },
});

window.queryClient = queryClient;
window.queryCache = queryCache;

const env = Utils.getEnv();
const OFFLINE_CACHE = `populi:${env}:offline`;

const localStoragePersister = createSyncStoragePersister({
  key: OFFLINE_CACHE,
  storage: window.localStorage,
});

const persistOptions = {
  queryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateMutation: () => false,
    shouldDehydrateQuery: (query) =>
      ["catalog", "settings"].includes(query.queryKey[0]),
  },
};

const PostHogProviderWrapper = ({ apiKey, options, children }) => {
  const currentEnvironment = getEnv();

  if (currentEnvironment !== ENVIRONMENTS.PROD) {
    return children;
  }

  return (
    <PostHogProvider apiKey={apiKey} options={options}>
      {children}
    </PostHogProvider>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(
  <PostHogProviderWrapper
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <ThemeProvider>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
      >
        <FeatureProvider>
          <UserProvider>
            <FlashProvider>
              <ToastProvider>
                <NavigationProvider>
                  <App />
                </NavigationProvider>
              </ToastProvider>
            </FlashProvider>
          </UserProvider>
        </FeatureProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
      <GlobalStyle />
    </ThemeProvider>
  </PostHogProviderWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
