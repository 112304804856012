import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
  useHover,
  safePolygon,
} from "@floating-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import Typography from "../Typography/Typography.js";
import styled from "styled-components";

export const MenuContainer = styled.div`
  min-width: 160px;
  width: max-content;
  border: 1px solid #dddbda;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

  ${(props) =>
    props?.navProfilePopover &&
    `
    border-radius: 6px;
    padding: 10px;
    width: 205px;
  `}

  ${(props) =>
    props?.navProfilePopover &&
    !props?.isNavOpen &&
    `
    padding: 8px;
  `}
`;

export const MenuItem = styled.div`
  font-family: "Inter";
  padding: 12px 18px;
  font-size: 14px;
  color: #080707;
  background-color: #ffffff;
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px solid #dddbda;
  }

  :hover {
    background-color: #f5f2fc;
  }

  ${(props) =>
    props?.navProfilePopover &&
    `
    font-size: 12px;

    :hover {
        border-radius: 6px;
      }

      :not(:last-child) {
        border-bottom: none;
      }
  `}
`;
export const ProfileMenu = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
  border-bottom: 1px solid #979797;
  margin-bottom: 10px;
  padding: 8px;
  padding-bottom: 12px;

  ${(props) =>
    props.$isNavOpen &&
    `
    justify-content: flex-start;
    gap: 10px;
    align-items: flex-start;

    svg {
        margin-top: 1px;
    }
  `}
`;

const Popover = ({
  TriggerComponent,
  actions,
  navProfilePopover = false,
  userInfo = "",
  isNavOpen = false,
  placement = "top",
  strategy = "absolute",
  offset: offsetValue = 0,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy,
    placement,
    middleware: [
      offset(offsetValue),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const hover = useHover(context, {
    enabled: isOpen, // force click to open the popover
    move: isOpen,
    handleClose: safePolygon({
      requireIntent: false,
      buffer: 1,
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
    hover,
  ]);

  const headingId = useId();

  return (
    <>
      <TriggerComponent setRef={refs.setReference} {...getReferenceProps()} />

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="Popover"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 10000000,
              outline: "none",
            }}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            <MenuContainer
              navProfilePopover={navProfilePopover}
              isNavOpen={isNavOpen}
            >
              {navProfilePopover && !isNavOpen && (
                <ProfileMenu
                  id="profileMenu"
                  $isNavOpen={isOpen}
                  data-cy="profileMenuButton"
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      minWidth: "18px",
                      width: "18px",
                      padding: "7px 6px",
                      color: "#fff",
                      backgroundColor: "#00C1F3",
                      borderRadius: "3px",
                    }}
                  />

                  {isOpen && (
                    <div
                      style={{
                        height: "48px",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography variant="h6" color="#080707" noMargin>
                        {userInfo?.name}
                      </Typography>
                      <Typography variant="sub-text" color="#080707" noMargin>
                        {userInfo?.orgName}
                      </Typography>
                      <Typography variant="sub-text" color="#080707" noMargin>
                        {userInfo?.role}
                      </Typography>
                    </div>
                  )}
                </ProfileMenu>
              )}
              {actions.map((action) => {
                return (
                  <MenuItem
                    key={action.name}
                    data-cy={action.dataCy}
                    navProfilePopover={navProfilePopover}
                    onClickCapture={(event) => action.onClick(event, setIsOpen)}
                  >
                    {action.name}
                  </MenuItem>
                );
              })}
            </MenuContainer>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};

export default Popover;
