import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createPassword } from "modules/auth/actions";
import CreatePasswordForm from "modules/auth/CreatePasswordForm";
import Page from "core/components/Page";
import useFlash from "core/hooks/useFlash";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuth from "core/hooks/useAuth";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

const CreatePassword = () => {
  const { error: flashError, setError: setFlashError, setSuccess } = useFlash();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isPasswordExpired, setIsPasswordExpired } = useCurrentUser();
  const { logout } = useAuth();

  const email = searchParams.get("email")?.split(" ").join("+");
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const origin = searchParams.get("origin");

  const [error, setError] = useState("");

  const handleSubmit = async (values) => {
    setError("");

    try {
      const result = await createPassword({ ...values, mode, oobCode, email });

      logout({ redirectToLogin: false });

      if (origin && origin === "tableau") {
        window.location.replace("https://tableau.populi.ai");
      } else {
        setSuccess(result.message);
        setIsPasswordExpired(false);
        setFlashError(null);
        navigate("/login");
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Page>
      <Grid columns="350px" justify="center" lift="15vh">
        <GridColumn>
          {isPasswordExpired ? (
            <img src="/dh-logo.svg" alt="logo" height="66px" />
          ) : (
            <>
              <Typography variant="h1">Create Password</Typography>
              <Typography variant="p">
                Password must be least 8 characters in length.
              </Typography>
            </>
          )}

          <CreatePasswordForm
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={flashError || error}
            isPasswordExpired={isPasswordExpired}
          />
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default CreatePassword;
