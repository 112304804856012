import { useEffect, useMemo, useState, useCallback } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JSONTree } from "react-json-tree";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useToaster from "core/hooks/useToaster";
import Typography from "core/components/Typography";
import Pill from "core/components/Pill";
import Utils from "common/utilities";

import Error from "core/components/Error";
import { Row } from "core/styles";
import { allOperators } from "core/components/QueryBuilder/constants";
import ProductBundleAccordion from "./ProductBundleAccordion";
import DeploymentReview from "./DeploymentReview";
import ButtonLink from "core/components/ButtonLink";
import {
  getOrganizations,
  getProductCatalog,
  updateOrganizationLicenses,
} from "../actions";
import { motion, AnimatePresence, animate } from "framer-motion";
import ProductPageBreadcrumbs from "./ProductPageBreadcrumbs";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import AnimatedProductPageContainer from "./AnimatedProductPageContainer";
import Button from "core/components/Button";
import ProductSettingsCard from "./ProductSettingsCard";
import BulkPasteInput from "core/components/BulkPasteInput";
import QueryBuilder from "core/components/QueryBuilder";
import Toggle from "core/components/Toggle";
import Combobox from "core/components/Combobox";
import NumberInput from "core/components/NumberInput";
import ProductTitleCard from "./ProductTitleCard";
import { mockModelOptions, stateOptions } from "./constants";
import {
  faArrowRotateLeft,
  faArrowsRotate,
} from "@fortawesome/pro-regular-svg-icons";
import DatePicker from "core/components/DatePicker";
import Skeleton from "react-loading-skeleton";
import { Label } from "core/components/Form";
import { DateTime, Interval } from "luxon";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";
import { createPortal } from "react-dom";
import { FieldError, Textarea } from "core/components/Form/styles";
import CopyButton from "core/components/CopyButton";
import DownloadButton from "core/components/DownloadButton";
import Success from "core/components/Success";
import InfoFlash from "core/components/InfoFlash";
import { isNullOperator } from "core/components/QueryBuilder/utilities";

const jsonTheme = {
  scheme: "google",
  author: "seth wright (http://sethawright.com)",
  base00: "#1d1f21",
  base01: "#282a2e",
  base02: "#373b41",
  base03: "#969896",
  base04: "#b4b7b4",
  base05: "#c5c8c6",
  base06: "#e0e0e0",
  base07: "#ffffff",
  base08: "#CC342B",
  base09: "#ff2a00",
  base0A: "#FBA922",
  base0B: "#008844",
  base0C: "#3971ED",
  base0D: "#3971ED",
  base0E: "#A36AC7",
  base0F: "#3971ED",
};

const tooltipId = "statusTooltip";
const dateTimeFormat = "FF";

const StyledLabel = styled.span`
  font-family: Source Code Pro;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledValue = styled.span`
  font-family: Source Code Pro;
`;

const jsonTreeSettings = {
  getItemString: (type, data, itemType, itemString) => {
    // Default
    // <span> {itemType} {itemString} </span>

    return <span> {itemType}</span>;
  },
  labelRenderer: (keyPath, nodeType, expanded, expandable) => {
    if (keyPath[0] === "root") {
      return <StyledLabel root>licenses:</StyledLabel>;
    }
    return <StyledLabel>{keyPath[0]}:</StyledLabel>;
  },
  valueRenderer: (valueAsString, value, keyPath) => {
    return <StyledValue>{valueAsString}</StyledValue>;
  },
  shouldExpandNodeInitially: (keyPath, data, level) => {
    return level <= 3;
  },
  collectionLimit: 15,
  theme: jsonTheme,
  invertTheme: true,
};

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const ComboButton = styled.div`
  color: #000000;
  font-family: "Inter";
  font-size: 14px;
  line-height: 14px;
  background-color: #ffffff;
  border: 1px solid #dddbda;
  padding: 6px 18px;
  width: fit-content;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:nth-child(2) {
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    background-color: #9f9f9f;
    border-color: #9f9f9f;
    color: #ffffff;
  }

  ${(props) =>
    props.isActive &&
    `
    color: #ffffff;
    background-color: #2f2e41;
    border-color: #2f2e41;

    &:hover {
      color: #ffffff;
      background-color: #2f2e41;
      border-color: #2f2e41;
    }
  `}
`;

export const ipsumDescriptum =
  "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu.";

const rootTitle = "Product Licenses";

const PRODUCT_BUNDLE_IDS = {
  MARK_FORECAST: "market_forecast",
  POP_INTEL: "population_intelligence",
  PROV_INTEL: "network_intelligence",
  MARK_INTEL: "market_intelligence",
};

const PRODUCT_BUNDLES = {
  [PRODUCT_BUNDLE_IDS.POP_INTEL]: {
    id: "population_intelligence",
    path: "population-intelligence",
    name: "Population Intelligence",
  },
  [PRODUCT_BUNDLE_IDS.PROV_INTEL]: {
    id: "network_intelligence",
    path: "network-intelligence",
    name: "Network Intelligence",
  },
  [PRODUCT_BUNDLE_IDS.MARK_INTEL]: {
    id: "market_intelligence",
    path: "market-intelligence",
    name: "Market Intelligence",
  },
  [PRODUCT_BUNDLE_IDS.MARK_FORECAST]: {
    id: "market_forecast",
    path: "market-forceast",
    name: "Market Forecast",
  },
};

const PRODUCT_IDS = {
  IDENTIFIED_CONSUMERS: "identified_consumers",
  DE_IDENTIFIED_CONSUMERS: "deidentified_consumers",
  PROPENSITY_MODELS: "propensity_models",
  NEW_MOVERS: "new_movers",
  MARKET_VOLUME_LITE: "market_volume_lite",
  PROVIDER_AFFILIATIONS: "provider_affiliations",
  PROVIDER_PATHWAYS: "provider_pathways",
  PROVIDER_BENCHMARKS: "provider_benchmarks",
  MARKET_VOLUME: "market_volume",
  CLAIM_VOLUME: "claim_volume",
  PATIENT_VOLUME: "patient_volume",
  MARKET_VOLUME_ONCOLOGY: "market_volume_oncology",
  PRICE_TRANSPARENCY: "price_transparency",
  MARKET_FORECAST: "market_forecast",
};

const getControlComponent = (componentType) => {
  switch (componentType) {
    case "subtitle":
      return ({ text }) => (
        <Typography variant="h4" color="black" mb="xs">
          {text}
        </Typography>
      );

    case "description":
      return ({ text }) => (
        <Typography variant="p" mb="md">
          {text}
        </Typography>
      );

    case "number":
      return ({ id, value, setValue, error, ...otherProps }) => (
        <NumberInput
          id={id}
          value={value}
          onChange={setValue}
          errorMessage={error}
          min="0"
          {...otherProps}
        />
      );

    case "date":
      return ({ id, value, setValue, error, ...otherProps }) => (
        <DatePicker
          id={id}
          value={value}
          onChange={setValue}
          errorMessage={error}
          {...otherProps}
        />
      );

    case "toggle":
      return ({ id, label, value, setValue, ...otherProps }) =>
        label ? (
          <Row gap="xs" style={{ margin: "10px 0px" }}>
            <Toggle id={id} value={value} onChange={setValue} {...otherProps} />
            <Label>{label}</Label>
          </Row>
        ) : (
          <Toggle id={id} value={value} onChange={setValue} {...otherProps} />
        );

    case "combobox":
      return ({ id, value, setValue, catalogFields, error, ...otherProps }) => (
        <Combobox
          id={id}
          value={value}
          onChange={setValue}
          options={catalogFields}
          errorMessage={error}
          {...otherProps}
        />
      );

    case "bulkInput":
      return ({ id, value, setValue, validator, ...otherProps }) => (
        <BulkPasteInput
          id={id}
          value={value}
          onChange={setValue}
          validator={validator}
          {...otherProps}
        />
      );

    case "queryBuilder":
      return ({ value, setValue, catalogFields, error, ...otherProps }) => (
        <QueryBuilder
          filters={value}
          onChange={setValue}
          fields={catalogFields}
          errorMessage={error}
          {...otherProps}
        />
      );

    default:
      return null;
  }
};

const LicenseControl = ({ control, error }) => {
  const { getValue, setValue, catalogFields, validator } = control.dynamicProps;

  const Component = useMemo(() => {
    return control.componentType === "custom"
      ? control.customComponent
      : getControlComponent(control.componentType);
  }, [control.componentType, control.customComponent]);

  const hasOptionalToggle =
    control.toggleKey && control.dynamicProps[control.toggleKey] === false;

  const value = getValue();
  const isToggleChecked = useMemo(() => {
    return hasOptionalToggle ? Array.isArray(value) : false;
  }, [value, hasOptionalToggle]);

  const handleToggleChange = (isChecked) => {
    if (isChecked) {
      setValue([]);
    } else {
      setValue(null);
    }
  };

  if (hasOptionalToggle) {
    return (
      <div style={{ display: "flex", alignItems: "start", gap: "15px" }}>
        <div>
          <Label style={{ marginBottom: "10px" }}>
            {control.staticProps.toggleLabel}
          </Label>
          <Toggle
            id={control.fieldKey}
            value={isToggleChecked}
            onChange={handleToggleChange}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Component
            id={control.fieldKey}
            value={getValue()}
            setValue={setValue}
            error={error}
            catalogFields={catalogFields}
            validator={validator}
            isDisabled={!isToggleChecked}
            {...control.staticProps} // label, name, etc.
          />
        </div>
      </div>
    );
  }

  return (
    <Component
      id={control.fieldKey}
      value={getValue()}
      setValue={setValue}
      error={error}
      catalogFields={catalogFields}
      validator={validator}
      {...{ ...control.dynamicProps, ...control.staticProps }} // label, name, etc.
    />
  );
};

const ProductPage = ({ product }) => {
  const { meta, page, fields, fieldErrors } = product;

  return (
    <div>
      <ErrorBoundary
        onError={(error) => console.log(error)}
        fallback={
          <Error>
            We encountered an issue while attempting to load this page. Please
            contact support for assistance.
          </Error>
        }
      >
        <ProductTitleCard>
          <div>
            <Typography variant="h4" color="white" mb="xs">
              {meta.name}
            </Typography>
            <Typography variant="p" color="white">
              {meta.description}
            </Typography>
          </div>
          <Toggle
            buttonStyle={{
              zIndex: 10000,
              border: "1px solid rgba(255, 255, 255, .4)",
            }}
            id={fields.isEnabled.dataId}
            value={page.isEnabled.getValue()}
            onChange={page.isEnabled.setValue}
          />
        </ProductTitleCard>

        {page.settings.map((setting) => {
          return (
            <ProductSettingsCard key={setting.title}>
              <Typography variant="h4" color="black" mb="xs">
                {" "}
                {setting.title}
              </Typography>
              <Typography variant="p" color="body" mb="md">
                {setting.description}
              </Typography>

              {setting.controls.map((control, index) => {
                return (
                  <LicenseControl
                    key={control.fieldKey || index}
                    index={index}
                    control={control}
                    error={fieldErrors[control.fieldKey] || ""}
                  />
                );
              })}
            </ProductSettingsCard>
          );
        })}
      </ErrorBoundary>
    </div>
  );
};

const API_DATE_FORMAT = "yyyy-MM-dd";

const dateRangeControlFormatter = ({ fieldValue }) => {
  if (
    !fieldValue ||
    typeof fieldValue !== "object" ||
    Array.isArray(fieldValue)
  ) {
    return null;
  }

  if (Object.hasOwn(fieldValue, "from") && Object.hasOwn(fieldValue, "to")) {
    return Interval.fromDateTimes(
      DateTime.fromFormat(fieldValue.from, API_DATE_FORMAT),
      DateTime.fromFormat(fieldValue.to, API_DATE_FORMAT)
    );
  }

  return null;
};

const dateRangeAPIFormatter = ({ fieldValue, isValid }) => {
  if (fieldValue && isValid) {
    const [start, end] = [
      fieldValue.start.toFormat(API_DATE_FORMAT),
      fieldValue.end.toFormat(API_DATE_FORMAT),
    ];

    return {
      operator: "between",
      from: start,
      to: end,
    };
  } else {
    return fieldValue;
  }
};

const dateRangeValidator = ({ product, field }) => {
  const { fieldErrors } = product;
  const { fieldId, fieldValue } = field;

  if (!fieldValue) {
    return [
      true,
      {
        ...fieldErrors,
        [fieldId]: "",
      },
    ];
  }

  if (!fieldValue.isValid) {
    return [
      false,
      {
        ...fieldErrors,
        [fieldId]: "Invalid date range.",
      },
    ];
  }

  const [start, end] = [
    fieldValue.start.toFormat(API_DATE_FORMAT),
    fieldValue.end.toFormat(API_DATE_FORMAT),
  ];

  if (!start || !end) {
    return [
      false,
      {
        ...fieldErrors,
        [fieldId]: "Invalid date range.",
      },
    ];
  }

  return [
    true,
    {
      ...fieldErrors,
      [fieldId]: "",
    },
  ];
};

const selectedStatesValidator = ({ product, field }) => {
  const { fieldErrors } = product;
  const { fieldId, fieldValue } = field;
  const isStateFilteringRequired = product.isStateFilteringRequired || false;
  const isDefined = fieldValue;
  const isArray = isDefined && Array.isArray(fieldValue);
  const hasItems = isArray && fieldValue.length;

  if (isStateFilteringRequired) {
    if (hasItems) {
      return [
        true,
        {
          ...fieldErrors,
          [fieldId]: "",
        },
      ];
    } else {
      return [
        false,
        {
          ...fieldErrors,
          [fieldId]: "Must select at least 1 state.",
        },
      ];
    }
  } else {
    if (isArray && !hasItems) {
      return [
        false,
        {
          ...fieldErrors,
          [fieldId]: "Must select at least 1 state.",
        },
      ];
    } else {
      return [
        true,
        {
          ...fieldErrors,
          [fieldId]: "",
        },
      ];
    }
  }
};

export const PRODUCTS = {
  [PRODUCT_IDS.MARKET_FORECAST]: {
    id: PRODUCT_IDS.MARKET_FORECAST,
    dataTableKey: null, // ? unknown
    meta: {
      name: "(Beta) Market Forecast",
      description:
        "Enable to license Market Forecast. Optionally configure licensed date range and patient origin by States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.MARK_FORECAST],
    },
    page: {
      path: "market-forecast",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Market Forecast",
          description:
            "Enable to license Market Forecast. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default. States are based on the State of the underlying Patients in the dataset.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.IDENTIFIED_CONSUMERS]: {
    id: PRODUCT_IDS.IDENTIFIED_CONSUMERS,
    dataTableKey: "consumer",
    meta: {
      name: "Identified Consumer Records",
      description:
        "Enable to license identified consumer records. Requires configuration of purchased attribute limit, specifically purchased attributes, total record limit, and filters to keep record count below record limit.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.POP_INTEL],
    },
    page: {
      path: "identified-consumer-records",
      isEnabled: {
        fieldKey: "isEnabled",
      },
      settings: [
        {
          title: "Anonymize Data",
          description:
            "Optionally deploy anonymized consumer records in place of identified consumer records. This is often used in early stages on onboarding where the customer has requested anonymized data for testing that mirrors real world data.",
          controls: [
            {
              fieldKey: "isAnonymized",
              componentType: "toggle",
              staticProps: {},
            },
          ],
        },
        {
          title: "Purchased Attributes",
          description:
            "Required configuration. Set the total number of purchased attributes available to the customer and allows selection of purchased attributes. The total number of selected attributes must be below the selected attribute limit. It is not required that all attributes be selected at time of configuration.",
          controls: [
            {
              fieldKey: "attributeLimit",
              componentType: "number",
              staticProps: {
                label: "Attribute Limit",
                placeholder: "Attribute Limit",
                name: "attributeLimit",
              },
            },
            {
              fieldKey: "purchasedAttributes",
              componentType: "combobox",
              staticProps: {
                label: "Selected Attributes",
                placeholder: "Select Attributes",
                name: "purchasedAttributes",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
        {
          title: "Consumer Records",
          description:
            "Required configuration. Set the total number of consumer records licensed to the customer. This number must be greater than zero.",
          controls: [
            {
              fieldKey: "recordLimit",
              componentType: "number",
              staticProps: {
                label: "Record Limit",
                placeholder: "Record Limit",
                name: "recordLimit",
              },
            },
            {
              fieldKey: null,
              componentType: "subtitle",
              staticProps: {
                text: "Consumer Filters",
              },
            },
            {
              fieldKey: null,
              componentType: "description",
              staticProps: {
                text: "Required configuration. This configuration requires the specific States and Zip Codes to limit the consumer records. Optionally, the activation filters” section provides more complex query building operations allowing the user to flexibly limit identified consumer records based on any attributes within the consumer data (e.g. Age between 25 and 80”).",
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                toggleLabel: "State Selection",
                multiValueMaxDisplayCount: 15,
              },
            },
            {
              fieldKey: "zipcodes",
              componentType: "bulkInput",
              staticProps: {
                label: "Zip Codes",
              },
            },
            {
              fieldKey: "consumerFilters",
              componentType: "queryBuilder",
              staticProps: {
                label: "Activation Filters",
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        type: "boolean", // used to build valdataIdators later
        dataId: "enabled", // path to value in product object
      },
      isAnonymized: {
        type: "boolean",
        dataId: "license.anonymized_records",
      },
      attributeLimit: {
        dataId: "license.identified_consumer_attribute_limit",
        type: "number",
        // validator: ({ product, field }) => {
        //   const { data, fields, fieldErrors } = product;
        //   const { fieldValue } = field;

        //   const purchasedAttributesDataId = fields.purchasedAttributes.dataId;
        //   const purchasedAttributesValue = getComplexFieldValue({
        //     dataId: purchasedAttributesDataId,
        //     data: data,
        //   });

        //   if (purchasedAttributesValue.length > fieldValue) {
        //     return [
        //       false,
        //       {
        //         ...fieldErrors,
        //         attributeLimit:
        //           "Limit must be increased to support Purchased Attributes.",
        //       },
        //     ];
        //   }

        //   return [
        //     true,
        //     {
        //       ...fieldErrors,
        //       attributeLimit: "",
        //     },
        //   ];
        // },
      },
      purchasedAttributes: {
        dataId: "license.purchased_attributes",
        type: "stringArray",
        validator: ({ product, field }) => {
          const { data, fields, fieldErrors } = product;
          const { fieldValue } = field;

          const attributeLimitDataId = fields.attributeLimit.dataId;
          const attributeLimitValue = getComplexFieldValue({
            dataId: attributeLimitDataId,
            data: data,
          });

          if (attributeLimitValue < fieldValue.length) {
            return [
              false,
              {
                ...fieldErrors,
                purchasedAttributes: "Number selected exceeds Attribute Limit.",
              },
            ];
          }

          return [
            true,
            {
              ...fieldErrors,
              purchasedAttributes: "",
            },
          ];
        },
      },
      recordLimit: {
        dataId: "license.identified_consumer_record_limit",
        type: "number",
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
      zipcodes: {
        dataId: "license.geography.zips",
        type: "stringArray",
      },
      consumerFilters: {
        dataId: "license.identified_consumer_filters",
        type: "objectArray",
        validator: ({ product, field }) => {
          const { fieldErrors } = product;
          const { fieldValue } = field;

          if (!Array.isArray(fieldValue)) {
            return [
              false,
              {
                ...fieldErrors,
                consumerFilters: "Consumer filters should be an array.",
              },
            ];
          }

          const deepCheckGroupRules = (group, check) => {
            return group.filters.every((filter) => {
              if (Object.hasOwn(filter, "filters")) {
                return deepCheckGroupRules(filter, check);
              } else {
                return check(filter);
              }
            });
          };

          const deepCheckGroup = (group) => {
            return (
              group.filters.length > 0 &&
              group.filters.every((filter) => {
                if (Object.hasOwn(filter, "filters")) {
                  return deepCheckGroup(filter);
                } else {
                  return true;
                }
              })
            );
          };

          const validateRule = (rule) => {
            if (Object.hasOwn(rule, "value")) {
              if (isNullOperator(rule.operator)) return rule.value === "";
              return rule.value !== "";
            }

            if (Object.hasOwn(rule, "from") || Object.hasOwn(rule, "to")) {
              return (
                Object.hasOwn(rule, "from") &&
                Object.hasOwn(rule, "to") &&
                rule.from !== "" &&
                rule.to !== ""
              );
            }

            return false;
          };

          const allGroupsHaveFilter = fieldValue
            .reduce((checks, currentFilter) => {
              if (Object.hasOwn(currentFilter, "filters")) {
                return checks.concat(deepCheckGroup(currentFilter));
              } else {
                return checks;
              }
            }, [])
            .every((groupHasFilters) => groupHasFilters === true);

          if (!allGroupsHaveFilter) {
            return [
              false,
              {
                ...fieldErrors,
                consumerFilters:
                  "Consumer filters cannot contain empty groups.",
              },
            ];
          }

          const allRulesContainValues = fieldValue
            .reduce((checks, currentFilter) => {
              if (Object.hasOwn(currentFilter, "filters")) {
                return checks.concat(
                  deepCheckGroupRules(currentFilter, validateRule)
                );
              } else {
                return checks.concat(validateRule(currentFilter));
              }
            }, [])
            .every((ruleHasValue) => ruleHasValue === true);

          if (!allRulesContainValues) {
            return [
              false,
              {
                ...fieldErrors,
                consumerFilters:
                  "Consumer filters cannot contain rules without values.",
              },
            ];
          }

          return [
            true,
            {
              ...fieldErrors,
              consumerFilters: "",
            },
          ];
        },
      },
    },
  },
  [PRODUCT_IDS.DE_IDENTIFIED_CONSUMERS]: {
    id: PRODUCT_IDS.DE_IDENTIFIED_CONSUMERS,
    dataTableKey: null,
    meta: {
      name: "Population Insights",
      description:
        "Enable to license de-identified consumer records and all related dashboards (e.g. Population Insights”). Optionally limit the de-identified consumer records to one or more selected States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.POP_INTEL],
    },
    page: {
      path: "population_insights",
      isEnabled: {
        fieldKey: "isEnabled",
      },
      settings: [
        {
          title: "Consumer Data",
          description:
            "Enable to license de-identified consumer records and all related dashboards (e.g. Population Insights”). Optionally limit the de-identified consumer records to one or more selected States.",
          controls: [
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.PROPENSITY_MODELS]: {
    id: PRODUCT_IDS.PROPENSITY_MODELS,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Propensity Models",
      description:
        "Enable to license propensity models which are appended to the customers identified consumer data. Requires configuration of total purchased model limit and selection of specific propensity models. ",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.POP_INTEL],
    },
    page: {
      path: "propensity-models",
      isEnabled: {
        fieldKey: "isEnabled",
      },
      settings: [
        {
          title: "Propensity Models",
          description:
            "'Purchased Model Limit' sets the total number of propensity models licensed in the customers contract. This number must be greater than 1. ‘Selected Propensity Models’ sets the specific models the customer has selected. The number of selected propensity models can not exceed the purchased model limit. It is not required that any or all models be selected at time of configuration.",
          controls: [
            {
              fieldKey: "modelLimit",
              componentType: "number",
              staticProps: {
                label: "Purchased Model Limit",
                placeholder: "Purchased Model Limit",
              },
            },
            {
              fieldKey: "selectedModels",
              componentType: "combobox",
              staticProps: {
                label: "Selected Propensity Models",
                placeholder: "Select Propensity Models",
                name: "selectedModels",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      modelLimit: {
        dataId: "license.model_limit",
        type: "number",
      },
      selectedModels: {
        dataId: "license.selected_models",
        type: "stringArray",
      },
    },
  },
  [PRODUCT_IDS.NEW_MOVERS]: {
    id: PRODUCT_IDS.NEW_MOVERS,
    dataTableKey: "consumer_new_mover",
    meta: {
      name: "New Movers",
      description:
        "Enable to license new mover records. Requires configuration of new mover record limit and filters to keep record count below record limit.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.POP_INTEL],
    },
    page: {
      path: "new-movers",
      isEnabled: {
        fieldKey: "isEnabled",
      },
      settings: [
        {
          title: "New Mover Records",
          description:
            "Required configuration. Set the total number of consumer records licensed to the customer. This number must be greater than zero.",
          controls: [
            {
              fieldKey: "recordLimit",
              componentType: "number",
              staticProps: {
                name: "recordLimit",
                placeholder: "Record Limit",
                label: "Record Limit",
              },
            },
            {
              fieldKey: null,
              componentType: "subtitle",
              staticProps: {
                text: "New Mover Filters",
              },
            },
            {
              fieldKey: null,
              componentType: "description",
              staticProps: {
                text: "Required configuration. This configuration requires the specific States and Zip Codes to limit the new mover records.",
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
            {
              fieldKey: "moverFilters",
              componentType: "queryBuilder",
              staticProps: {
                label: "Activation Filters",
              },
            },
            // {
            //   fieldKey: "moverFilters",
            //   componentType: "custom",
            //   customComponent: NewMoversFilters,
            // },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      recordLimit: {
        dataId: "license.new_mover_record_limit",
        type: "number",
      },
      moverFilters: {
        dataId: "license.new_mover_filters",
        type: "objectArray",
        validator: ({ product, field }) => {
          const { fieldErrors } = product;
          const { fieldValue } = field;

          if (!Array.isArray(fieldValue)) {
            return [
              false,
              {
                ...fieldErrors,
                moverFilters: "Mover filters should be an array.",
              },
            ];
          }

          const deepCheckGroupRules = (group, check) => {
            return group.filters.every((filter) => {
              if (Object.hasOwn(filter, "filters")) {
                return deepCheckGroupRules(filter, check);
              } else {
                return check(filter);
              }
            });
          };

          const deepCheckGroup = (group) => {
            return (
              group.filters.length > 0 &&
              group.filters.every((filter) => {
                if (Object.hasOwn(filter, "filters")) {
                  return deepCheckGroup(filter);
                } else {
                  return true;
                }
              })
            );
          };

          const validateRule = (rule) => {
            if (Object.hasOwn(rule, "value")) {
              if (isNullOperator(rule.operator)) return rule.value === "";
              return rule.value !== "";
            }

            if (Object.hasOwn(rule, "from") || Object.hasOwn(rule, "to")) {
              return (
                Object.hasOwn(rule, "from") &&
                Object.hasOwn(rule, "to") &&
                rule.from !== "" &&
                rule.to !== ""
              );
            }

            return false;
          };

          const allGroupsHaveFilter = fieldValue
            .reduce((checks, currentFilter) => {
              if (Object.hasOwn(currentFilter, "filters")) {
                return checks.concat(deepCheckGroup(currentFilter));
              } else {
                return checks;
              }
            }, [])
            .every((groupHasFilters) => groupHasFilters === true);

          if (!allGroupsHaveFilter) {
            return [
              false,
              {
                ...fieldErrors,
                moverFilters: "Mover filters cannot contain empty groups.",
              },
            ];
          }

          const allRulesContainValues = fieldValue
            .reduce((checks, currentFilter) => {
              if (Object.hasOwn(currentFilter, "filters")) {
                return checks.concat(
                  deepCheckGroupRules(currentFilter, validateRule)
                );
              } else {
                return checks.concat(validateRule(currentFilter));
              }
            }, [])
            .every((ruleHasValue) => ruleHasValue === true);

          if (!allRulesContainValues) {
            return [
              false,
              {
                ...fieldErrors,
                moverFilters:
                  "Mover filters cannot contain rules without values.",
              },
            ];
          }

          return [
            true,
            {
              ...fieldErrors,
              moverFilters: "",
            },
          ];
        },
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME_LITE]: {
    id: PRODUCT_IDS.MARKET_VOLUME_LITE,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Market Volume Lite",
      description:
        "Enable to license market volume lite. This product is primarily for Population Intelligence customers who do not have any other Provider/Market dashboards. Optionally configure licensed date range and service location states.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.POP_INTEL],
    },
    page: {
      path: "market-volume-lite",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Market Volume Lite",
          description:
            "Enable to license market volume lite. This product is primarily for Population Intelligence customers who do not have any other Provider/Market dashboards. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.PROVIDER_AFFILIATIONS]: {
    id: PRODUCT_IDS.PROVIDER_AFFILIATIONS,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Provider Affiliations",
      description:
        "Enable to license Provider Affiliations. Optionally configure licensed date range.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.PROV_INTEL],
    },
    page: {
      path: "provider-affiliations",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Provider Affiliations",
          description:
            "Enable to license Provider Affiliations. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
    },
  },
  [PRODUCT_IDS.PROVIDER_PATHWAYS]: {
    id: PRODUCT_IDS.PROVIDER_PATHWAYS,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Provider Pathways",
      description:
        "Enable to license provider pathways. Optionally configure licensed date ranges and inbound service location states.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.PROV_INTEL],
    },
    page: {
      path: "provider-pathways",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Provider Pathways",
          description:
            "Enable to license provider pathways. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.PROVIDER_BENCHMARKS]: {
    id: PRODUCT_IDS.PROVIDER_BENCHMARKS,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Provider Benchmarks",
      description:
        "Enable to license Provider Benchmarks. Optionally configure licensed date range.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.PROV_INTEL],
    },
    page: {
      path: "provider-benchmarks",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Provider Benchmarks",
          description:
            "Enable to license Provider Benchmarks. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied.",

          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME]: {
    id: PRODUCT_IDS.MARKET_VOLUME,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Market Volume",
      description:
        "Enable to license Market Volume. Optionally configure licensed date range and service location States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.PROV_INTEL],
    },
    page: {
      path: "market-volume",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Market Volume",
          description:
            "Enable to license Market Volume. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.CLAIM_VOLUME]: {
    id: PRODUCT_IDS.CLAIM_VOLUME,
    dataTableKey: null,
    meta: {
      name: "Claim Volume",
      description:
        "Enable to license Claim Volume. Optionally configure licensed date range and service location States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.MARK_INTEL],
    },
    page: {
      path: "claim-volume",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Claim Volume",
          description:
            "Enable to license Claim Volume. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected Service Location States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.PATIENT_VOLUME]: {
    id: PRODUCT_IDS.PATIENT_VOLUME,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Patient Volume",
      description:
        "Enable to license Patient Volume. Optionally configure licensed date range and inbound service location States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.MARK_INTEL],
    },
    page: {
      path: "market-patient-volume",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Patient Volume",
          description:
            "Enable to license Patient Volume. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME_ONCOLOGY]: {
    id: PRODUCT_IDS.MARKET_VOLUME_ONCOLOGY,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Oncology Insights",
      description:
        "Enable to license Oncology Insights. Optionally configure licensed date range and service location States.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.MARK_INTEL],
    },
    page: {
      path: "market-volume-oncology",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Oncology Insights",
          description:
            "Enable to license Oncology Insights. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied. Optionally toggle on State filter to specifically license one or more US States. When this is kept off, all States will be licensed by default.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
            {
              fieldKey: "states",
              componentType: "combobox",
              toggleKey: "isStateFilteringRequired",
              staticProps: {
                label: "Selected States",
                toggleLabel: "State Selection",
                placeholder: "Select States",
                name: "states",
                isMultiSelect: true,
                isSearchable: true,
                multiValueMaxDisplayCount: 15,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
      states: {
        dataId: "license.geography.states",
        type: "stringArray",
        validator: selectedStatesValidator,
      },
    },
  },
  [PRODUCT_IDS.PRICE_TRANSPARENCY]: {
    id: PRODUCT_IDS.PRICE_TRANSPARENCY,
    dataTableKey: null, // ? unknown
    meta: {
      name: "Price Transparency",
      description:
        "Enable to license Price Transparency. Optionally configure licensed date range.",
      bundle: PRODUCT_BUNDLES[PRODUCT_BUNDLE_IDS.MARK_INTEL],
    },
    page: {
      path: "price-transparency",
      isEnabled: { fieldKey: "isEnabled" },
      settings: [
        {
          title: "Price Transparency",
          description:
            "Enable to license Provider Transparency. Optionally configure licensed dates to limit the underlying data between the selected dates. When no licensed date is selected, no date filter is applied.",
          controls: [
            {
              fieldKey: "licensedDates",
              componentType: "date",
              staticProps: {
                name: "licensedDates",
                label: "Licensed Dates",
                isRange: true,
              },
            },
          ],
        },
      ],
    },
    fields: {
      isEnabled: {
        dataId: "enabled",
        type: "boolean",
      },
      licensedDates: {
        dataId: "license.dates",
        type: "dateRange",
        toControlFormat: dateRangeControlFormatter,
        toAPIFormat: dateRangeAPIFormatter,
        validator: dateRangeValidator,
      },
    },
  },
};

const productRoutes = Object.values(PRODUCTS).map((product) => {
  return {
    path: `${product.meta.bundle.path}/${product.page.path}`,
    product,
    breadcrumbs: [
      { title: rootTitle, to: "../" },
      { title: product.meta.bundle.name, disabled: true },
      { title: product.meta.name },
    ],
  };
});

const DEPLOYMENT_STATUSES = {
  DEFAULT: "Not Configured",
  SUCCESS: "Deployed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed",
  QUEUED: "Queued",
  TIMEOUT: "Timeout",
  UNKNOWN: "Unknown",
};

const DEPLOYMENT_STATUS_MAP = {
  "not deployed": DEPLOYMENT_STATUSES.DEFAULT,
  "in progress": DEPLOYMENT_STATUSES.IN_PROGRESS,
  queued: DEPLOYMENT_STATUSES.QUEUED,
  succeeded: DEPLOYMENT_STATUSES.SUCCESS,
  failed: DEPLOYMENT_STATUSES.FAILED,
  timeout: DEPLOYMENT_STATUSES.TIMEOUT,
  unknown: DEPLOYMENT_STATUSES.UNKNOWN,
};

const DEPLOYMENT_STATUS_PILL_VARIANTS = {
  [DEPLOYMENT_STATUSES.DEFAULT]: "default",
  [DEPLOYMENT_STATUSES.SUCCESS]: "success",
  [DEPLOYMENT_STATUSES.IN_PROGRESS]: "info",
  [DEPLOYMENT_STATUSES.FAILED]: "failed",
  [DEPLOYMENT_STATUSES.QUEUED]: "pending",
  [DEPLOYMENT_STATUSES.TIMEOUT]: "failed",
  [DEPLOYMENT_STATUSES.UNKNOWN]: "warning",
};

export const getComplexFieldValue = ({ dataId, data }) => {
  if (!dataId.includes(".")) {
    return data[dataId];
  } else {
    const splitFieldKey = dataId.split(".");
    const nextKey = splitFieldKey.shift();

    return getComplexFieldValue({
      dataId: splitFieldKey.join("."),
      data: data[nextKey],
    });
  }
};

const buildComplexFieldState = ({ prevState = {}, fieldKey, newValue }) => {
  if (!fieldKey.includes(".")) {
    return { ...prevState, [fieldKey]: newValue };
  } else {
    const splitFieldKey = fieldKey.split(".");
    const nextKey = splitFieldKey.shift();

    return {
      ...prevState,
      [nextKey]: splitFieldKey.length
        ? buildComplexFieldState({
            prevState: prevState[nextKey],
            fieldKey: splitFieldKey.join("."),
            newValue,
          })
        : newValue,
    };
  }
};

const deepMergeObjects = (
  prevUpdates = {},
  nextUpdates = {},
  id = "standard"
) => {
  if ([prevUpdates, nextUpdates].includes(null)) {
    return prevUpdates ?? nextUpdates;
  }

  const uniqueKeys = [
    ...new Set([...Object.keys(prevUpdates), ...Object.keys(nextUpdates)]),
  ];

  return uniqueKeys.reduce((mergedValues, key) => {
    const prevValue = prevUpdates[key];
    const nextValue = nextUpdates[key];

    if ([prevValue, nextValue].includes(undefined)) {
      return {
        ...mergedValues,
        [key]: nextValue ?? prevValue,
      };
    }

    const isPrevValueObject =
      prevValue && !Array.isArray(prevValue) && typeof prevValue === "object";
    const isNextValueObject =
      nextValue && !Array.isArray(nextValue) && typeof nextValue === "object";

    if (isPrevValueObject && isNextValueObject) {
      return {
        ...mergedValues,
        [key]: deepMergeObjects(prevValue, nextValue, "recursive"),
      };
    } else {
      return {
        ...mergedValues,
        [key]: nextValue,
      };
    }
  }, {});
};

const areObjectsDeeplyEqual = (prevState, nextState) => {
  // Check types
  const typesMatch = Object.is(typeof prevState, typeof nextState);
  if (!typesMatch) return false;

  // Check if objects or arrays
  const isPrevObject =
    prevState && !Array.isArray(prevState) && typeof prevState === "object";
  const isNextObject =
    nextState && !Array.isArray(nextState) && typeof nextState === "object";
  const isPrevArray = prevState && Array.isArray(prevState);
  const isNextArray = nextState && Array.isArray(nextState);
  const areObjects = isPrevObject && isNextObject;
  const areArrays = isPrevArray && isNextArray;

  // Check if type object but mismatch on structure
  if ([isPrevObject && isNextArray, isPrevArray && isNextObject].includes(true))
    return false;

  // Check primitives
  if (!areObjects && !areArrays) {
    return Object.is(prevState, nextState);
  }

  // If objects or arrays check length of keys/items
  const prevKeys = Object.keys(prevState);
  const nextKeys = Object.keys(nextState);
  if (!Object.is(prevKeys.length, nextKeys.length)) return false;

  // If objects, check all keys have the same name
  if (areObjects) {
    const objectsHaveSameKeys = prevKeys.every((prevKey) =>
      Object.hasOwn(nextState, prevKey)
    );
    if (!objectsHaveSameKeys) return false;
  }

  // Finally recursively compare nested quality
  return prevKeys.every((prevKey) =>
    areObjectsDeeplyEqual(prevState[prevKey], nextState[prevKey])
  );
};

// Represents API Schema
const defaultLicenseState = {
  // Market Forecast
  [PRODUCT_IDS.MARKET_FORECAST]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },

  // Population Intelligence
  [PRODUCT_IDS.IDENTIFIED_CONSUMERS]: {
    enabled: false,
    license: {
      // First section
      anonymized_records: false,

      // Second section
      identified_consumer_attribute_limit: 0,
      purchased_attributes: [],

      // Third section
      identified_consumer_record_limit: 0,
      geography: { states: [], zips: [] },
      identified_consumer_filters: [],
    },
  },
  [PRODUCT_IDS.DE_IDENTIFIED_CONSUMERS]: {
    enabled: false,
    license: {
      geography: { states: [] },
    },
  },
  [PRODUCT_IDS.PROPENSITY_MODELS]: {
    enabled: false,
    license: {
      model_limit: 0,
      selected_models: [],
    },
  },
  [PRODUCT_IDS.NEW_MOVERS]: {
    enabled: false,
    license: {
      new_mover_record_limit: 0,
      new_mover_filters: [],
      geography: {
        states: [],
        zips: [],
      },
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME_LITE]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },

  // Network Intelligence
  [PRODUCT_IDS.PROVIDER_AFFILIATIONS]: {
    enabled: false,
    license: {
      dates: null,
    },
  },
  [PRODUCT_IDS.PROVIDER_PATHWAYS]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },
  [PRODUCT_IDS.PROVIDER_BENCHMARKS]: {
    enabled: false,
    license: {
      dates: null,
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },
  [PRODUCT_IDS.CLAIM_VOLUME]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },

  // Market Intelligence
  [PRODUCT_IDS.PATIENT_VOLUME]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },
  [PRODUCT_IDS.MARKET_VOLUME_ONCOLOGY]: {
    enabled: false,
    license: {
      geography: { states: [] },
      dates: null,
    },
  },
  [PRODUCT_IDS.PRICE_TRANSPARENCY]: {
    enabled: false,
    license: {
      dates: null,
    },
  },
};

const productIds = Object.keys(defaultLicenseState);

// TODO: These enums belong with the querybuilder
const FIELD_TYPES = {
  INT64: "number",
  FLOAT64: "number",
  STRING: "text",
  DATE: "date",
  SELECT: "select",
  ZIPCODE: "zip",
};

const DATE_TYPES = {
  DATE: "date",
  MONTH: "month",
  YEAR: "year",
  QUARTER: "quarter",
};

const VALUE_EDITOR_TYPES = {
  DATE: "date",
  MULTISELECT: "multiselect",
};

const getFieldType = (field) => {
  if (field.values?.length) {
    return FIELD_TYPES.SELECT;
  }

  if (field.id.includes("zip_code")) {
    return FIELD_TYPES.ZIPCODE;
  }

  return FIELD_TYPES[field.type];
};

const createQueryBuilderField = (field) => {
  const type = getFieldType(field);

  const baseProperties = {
    group: field.group,
    inputType: type,
    label: field.name,
    value: field.id,
    name: field.id,
    operators: allOperators,
    original_field: field,
  };

  if (type === FIELD_TYPES.SELECT) {
    return {
      ...baseProperties,
      values: field.values
        // filter out null || undefined (let api team know)
        .filter((value) => {
          // if (!value)
          // console.info("Discovered an empty field value!", { field });
          return !!value;
        })
        .map((value) => {
          return {
            label: value,
            value: value,
            name: value,
          };
        }),
      valueEditorType: VALUE_EDITOR_TYPES.MULTISELECT,
    };
  } else if (type === FIELD_TYPES.DATE) {
    return {
      ...baseProperties,
      // TODO: How to get this?
      dateType: DATE_TYPES.DATE,
      valueEditorType: VALUE_EDITOR_TYPES.DATE,
    };
  } else {
    return baseProperties;
  }
};

const getQueryBuilderFieldsFromCatalog = (table) => {
  const queryBuilderFields = Object.values(table.fields)
    .filter((field) => {
      return field.is_filterable;
    })
    .map(createQueryBuilderField);

  // TODO: Grouping fields for querybuilder has some issues,
  // so just returning ungrouped here
  return queryBuilderFields;
};

const groupFields = (fields) => {
  const groupedFieldsMap = fields.reduce((groupedFields, field) => {
    const fieldOption = {
      label: field.name,
      value: field.id,
    };

    return {
      ...groupedFields,
      [field.group]: groupedFields[field.group]
        ? {
            ...groupedFields[field.group],
            children: groupedFields[field.group].children.concat(fieldOption),
          }
        : {
            groupId: field.group,
            label: field.group,
            children: [fieldOption],
          },
    };
  }, {});

  return Object.values(groupedFieldsMap);
};

const getPurchasableAttributeOptionsFromCatalog = (table) => {
  const filteredFields = Object.values(table.fields).filter((field) => {
    return field.consumer_attribute_package === "Purchasable Attributes";
  });

  return groupFields(filteredFields);
};

export const getStateOptionsFromCatalog = (table) => {
  const stateAttribute = table?.state_attribute;

  if (stateAttribute) {
    const stateFields = Object.values(table.fields).find((field) => {
      return field.id === stateAttribute;
    });

    if (stateFields.values?.length) {
      return stateFields.values.map((state) => {
        return {
          label: state,
          value: state,
        };
      });
    } else {
      return stateOptions;
    }
  } else {
    return stateOptions;
  }
};

export const getModelOptionsFromCatalog = (models) => {
  const modelKeys = Object.keys(models);

  if (modelKeys.length) {
    return modelKeys.map((modelKey) => {
      const model = models[modelKey];

      return {
        label: model.name,
        value: model.id,
      };
    });
  } else {
    return mockModelOptions;
  }
};

const getOptionsFromCatalog = (table, models, fieldKey) => {
  switch (fieldKey) {
    case "purchasedAttributes":
      return getPurchasableAttributeOptionsFromCatalog(table);

    case "states":
      return getStateOptionsFromCatalog(table);

    case "selectedModels":
      return getModelOptionsFromCatalog(models);

    default:
      return [];
  }
};

const getDerivedDefaultStateFromCatalog = ({
  staticDefault,
  productCatalog,
}) => {
  const dynamicDefault = Object.entries(staticDefault).reduce(
    (defaults, [productId, product]) => {
      const catalogEntry = productCatalog[productId] || {};

      const isStatesProductField = product.license?.geography?.states;
      const isStateFilteringOptional =
        catalogEntry.is_state_filtering_required === false;

      if (isStatesProductField && isStateFilteringOptional) {
        const updatedDefaultProduct = {
          ...product,
          license: {
            ...product?.license,
            geography: {
              ...product?.license?.geography,
              states: null,
            },
          },
        };

        defaults[productId] = updatedDefaultProduct;
      } else {
        defaults[productId] = product;
      }

      return defaults;
    },
    {}
  );

  return dynamicDefault;
};

const useProductLicensingFormState = ({
  defaultState,
  serverState,
  productCatalog,
  isLoading,
}) => {
  const [auditTrail, setAuditTrail] = useState([]);

  // UI State for Product Forms
  // Used for dirty checking and displaying configured settings count
  const [formState, setFormState] = useState(() => {
    return Object.entries(PRODUCTS).reduce((defaultFormState, schemaEntry) => {
      const [productId, product] = schemaEntry;
      const totalControls = product.page.settings.reduce(
        (count, setting) =>
          count + setting.controls.filter((control) => control.fieldKey).length,
        0
      );

      return {
        ...defaultFormState,
        [productId]: {
          ...product,
          isDirty: false,
          configuredControls: 0, // dynamically updated in formData
          totalControls: totalControls,
          hasError: false, // dynamically updated in setHandlers
          fieldErrors: Object.keys(product.fields).reduce(
            (defaults, key) => ({ ...defaults, [key]: "" }),
            {}
          ),
        },
      };
    }, {});
  });

  const [serverData, setServerData] = useState(() => {
    return deepMergeObjects(defaultState, serverState);
  });

  const [formData, setFormData] = useState(() => {
    return deepMergeObjects(defaultState, serverState);
  });

  const [isFormReady, setFormReady] = useState(false);

  useEffect(() => {
    if (!isFormReady && !isLoading) {
      setFormReady(true);

      setFormData((previous) => {
        const dynamicDefaultState = getDerivedDefaultStateFromCatalog({
          staticDefault: previous,
          productCatalog,
        });

        return deepMergeObjects(dynamicDefaultState, serverState);
      });
      setServerData((previous) => deepMergeObjects(previous, serverState));
    }
  }, [isLoading, isFormReady, productCatalog, serverState]);

  useEffect(() => {
    if (!isLoading) {
      setServerData((previous) => deepMergeObjects(previous, serverState));
    }
  }, [isLoading, serverState]);

  const getFieldValue = useCallback(
    (productId, fieldId) => {
      const product = PRODUCTS[productId];

      const field = product.fields[fieldId];

      const dataId = field.dataId;

      const fieldValue = getComplexFieldValue({
        dataId: dataId,
        data: formData[productId],
      });

      const defaultControlFormatter = ({ fieldValue }) => fieldValue;
      const fieldControlFormatter =
        field.toControlFormat || defaultControlFormatter;

      const valueInControlFormat = fieldControlFormatter({
        fieldValue: fieldValue,
      });

      return valueInControlFormat;
    },
    [formData]
  );

  const createSetFieldValueHandler = useCallback(
    (productId, fieldId) => {
      return (newValue) => {
        const product = getDynamicProduct(productId);
        const field = product.fields[fieldId];
        const dataId = field.dataId;
        const control = product.page.settings.reduce(
          (foundControl, currentSetting) => {
            const matchingControl = currentSetting.controls.find(
              (control) => control.fieldKey === fieldId
            );

            return matchingControl || foundControl;
          },
          {}
        );

        const defaultValidator = () => {
          return [true, product.fieldErrors];
        };
        const fieldValidator = field.validator || defaultValidator;

        const [isFieldValid, updatedFieldErrors] = fieldValidator({
          product: product,
          field: { fieldId: fieldId, fieldValue: newValue },
        });

        // Used to map control format to API format
        const defaultAPIFormatter = ({ fieldValue }) => fieldValue;
        const fieldAPIFormatter = field.toAPIFormat || defaultAPIFormatter;
        const valueInAPIFormat = fieldAPIFormatter({
          fieldValue: newValue,
          isValid: isFieldValid,
          options: control?.dynamicProps?.catalogFields,
        });

        // Update form data for related product
        setFormData((prevState) => {
          const previousProductState = prevState[productId];

          // !!! May want to rename some things to separate idea of field from data a bit better
          const nextProductState = buildComplexFieldState({
            prevState: previousProductState,
            fieldKey: dataId,
            newValue: valueInAPIFormat,
          });

          // Add update as a partial to audit trail
          const nextPartialUpdate = buildComplexFieldState({
            prevState: {},
            fieldKey: dataId,
            newValue: valueInAPIFormat,
          });

          setAuditTrail((prevState) =>
            prevState.concat({
              productId: productId,
              productUpdates: nextPartialUpdate,
            })
          );

          // Finalize product form updates
          return {
            ...prevState,
            [productId]: nextProductState,
          };
        });

        // Update form state for product
        setFormState((prevState) => {
          const prevProductState = prevState[productId];

          if (prevProductState) {
            return {
              ...prevState,
              [productId]: {
                ...prevProductState,
                hasError: !isFieldValid,
                fieldErrors: updatedFieldErrors,
              },
            };
          } else {
            return prevState;
          }
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData]
  );

  const getCatalogFields = useCallback(
    (productId, dataTableKey, componentType, fieldKey) => {
      // ! Needs to load
      if (!productCatalog) return [];

      switch (componentType) {
        case "queryBuilder":
          return getQueryBuilderFieldsFromCatalog(
            productCatalog[productId].data[dataTableKey]
          );

        case "combobox":
          if (fieldKey === "selectedModels") {
            return getOptionsFromCatalog(
              null,
              productCatalog[productId].models,
              fieldKey
            );
          }

          return dataTableKey
            ? getOptionsFromCatalog(
                productCatalog[productId].data[dataTableKey],
                null,
                fieldKey
              )
            : fieldKey === "states"
            ? stateOptions
            : [];

        default:
          return [];
      }
    },
    [productCatalog]
  );

  const formSchema = useMemo(() => {
    return Object.values(PRODUCTS).reduce((licensesSchema, product) => {
      const catalogEntry = productCatalog ? productCatalog[product.id] : {};

      const isStateFilteringRequired =
        catalogEntry?.is_state_filtering_required;

      const enhancedProduct = {
        ...product,
        isStateFilteringRequired,
        page: {
          ...product.page,
          isEnabled: {
            ...product.page.isEnabled,
            getValue: () => getFieldValue(product.id, "isEnabled"),
            setValue: createSetFieldValueHandler(product.id, "isEnabled"),
          },
          settings: product.page.settings.map((setting) => {
            return {
              ...setting,
              controls: setting.controls.map((control) => {
                // fieldKey => the UI aliased name for corresponding API property
                const fieldKey = control.fieldKey;
                // dataId => complex dotted id that represents the location of the value withing the API schema
                // Will be null for static components (i.e. descriptions)
                // const dataId = fieldKey
                //   ? product.fields[fieldKey].dataId
                //   : null;

                const convertGrainToDateType = (dateGrain) => {
                  switch (dateGrain) {
                    case "monthly":
                      return "month";

                    case "yearly":
                      return "year";

                    case "quarterly":
                      return "quarter";

                    default:
                      return "date";
                  }
                };

                const dateProps =
                  control.componentType === "date"
                    ? { type: convertGrainToDateType(catalogEntry?.date_grain) }
                    : {};

                return {
                  ...control,
                  dynamicProps: {
                    getValue: () =>
                      fieldKey ? getFieldValue(product.id, fieldKey) : null,
                    setValue: createSetFieldValueHandler(product.id, fieldKey),
                    catalogFields: ["combobox", "queryBuilder"].includes(
                      control.componentType
                    )
                      ? getCatalogFields(
                          product.id,
                          product.dataTableKey,
                          control.componentType,
                          fieldKey
                        )
                      : null,
                    // TODO: add validators based on field type
                    // !!! There is a different between a control validator and a field validator at the form level
                    validator:
                      fieldKey === "zipcodes" ? Utils.validateZip : null,
                    isStateFilteringRequired,

                    ...dateProps,
                  },
                };
              }),
            };
          }),
        },
      };

      licensesSchema[product.id] = enhancedProduct;

      return licensesSchema;
    }, {});
  }, [
    getCatalogFields,
    getFieldValue,
    createSetFieldValueHandler,
    productCatalog,
  ]);

  const getDynamicProduct = (productId) => {
    return {
      // meta + ui specific
      ...formState[productId],

      // Product Catalog + UI handlers for Fields
      ...formSchema[productId],

      // License API representation
      data: formData[productId],
    };
  };

  const getAllProducts = () => {
    return Object.keys(formSchema).map((productId) =>
      getDynamicProduct(productId)
    );
  };

  const clearAuditTrail = () => setAuditTrail([]);

  const getPreparedLicenses = () => {
    // No updates for sure
    if (!auditTrail.length) {
      return null;
    }

    // Generate and prepare an object containing all UI draft changes
    const mergedUpdatesFromAuditTrail = auditTrail.reduce(
      (mergedUpdates, partialUpdate) => {
        const { productId, productUpdates } = partialUpdate;
        const existingProductUpdates = mergedUpdates[productId] || {};

        mergedUpdates[productId] = deepMergeObjects(
          existingProductUpdates,
          productUpdates,
          "audit trail"
        );

        return mergedUpdates;
      },
      {}
    );

    // Compare those with server state we have

    // Dirty checking and UI state updates
    // 1. Check if new state equals server state
    const areFormUpdatesEqualToServerState = areObjectsDeeplyEqual(
      formData,
      serverData
    );

    // ! Make sure that server state updates are used here and not initially loaded license
    // console.log({ areFormUpdatesEqualToServerState, formData, serverData });

    return areFormUpdatesEqualToServerState
      ? null
      : {
          products: mergedUpdatesFromAuditTrail,
        };
  };

  const getProductsEnabled = () => {
    return getAllProducts().filter((product) => {
      return product.data.enabled;
    });
  };

  const getProductIdsEnabled = () => {
    return getProductsEnabled().map((product) => product.id);
  };

  const getSettingsControlsConfigured = () => {
    return Object.keys(formData).reduce((counts, productId) => {
      const productLicense = formData[productId].license || {};

      counts[productId] = Object.entries(productLicense).reduce(
        (count, entry) => {
          const [key, value] = entry;

          if (!value) return count;
          if (!Object.hasOwn(defaultLicenseState[productId].license, key))
            return count;

          if (typeof value === "boolean" && value) return count + 1;
          if (typeof value === "number" && value > 0) return count + 1;
          if (Array.isArray(value) && value.length) return count + 1;
          if (typeof value === "string" && value) return count + 1;
          if (key === "geography") {
            if (value.states?.length) {
              count += 1;
            }
            if (value.zips?.length) {
              count += 1;
            }
          }
          if (key === "dates") {
            if (
              value &&
              value.to &&
              value.from &&
              value.operator === "between"
            ) {
              count += 1;
            }
          }

          return count;
        },
        0
      );

      return counts;
    }, {});
  };

  const importLicense = (importedConfig = {}) => {
    const importAuditEvents = Object.keys(importedConfig).reduce(
      (productEvents, productId) => {
        const importedLicense = importedConfig[productId];
        const productFields = PRODUCTS[productId]?.fields || {};
        const fieldDataIds = Object.values(productFields)
          ?.map((field) => field.dataId)
          .reduce((collection, dataId) => {
            if (dataId.includes(".")) {
              return collection.concat(dataId.split("."));
            }

            return collection.concat(dataId);
          }, []);

        const generateAuditEvents = (licenseData, allowedFieldKeys = []) => {
          return Object.keys(licenseData).reduce((settingEvents, dataKey) => {
            const data = licenseData[dataKey];

            if (data && typeof data === "object" && !Array.isArray(data)) {
              const strainedData = Object.entries(data).reduce(
                (object, entry) => {
                  const [key, value] = entry;

                  if (allowedFieldKeys.includes(key)) {
                    object[key] = value;
                    return object;
                  }

                  return object;
                },
                {}
              );

              return settingEvents.concat({
                productId: productId,
                productUpdates: { [dataKey]: strainedData },
              });
            }

            return settingEvents.concat({
              productId: productId,
              productUpdates: { [dataKey]: data },
            });
          }, []);
        };

        const generatedAuditEvents = generateAuditEvents(
          importedLicense,
          fieldDataIds
        );

        return productEvents.concat(generatedAuditEvents);
      },
      []
    );

    setAuditTrail(importAuditEvents);

    setFormData((previous) => {
      const mergedConfig = deepMergeObjects(previous, importedConfig);
      return mergedConfig;
    });
  };

  return {
    getDynamicProduct,
    getProductIdsEnabled,
    getPreparedLicenses,
    getSettingsControlsConfigured,
    auditTrail,
    clearAuditTrail,
    getAllProducts,
    importLicense,
  };
};

const ImportLicenseForm = ({ onSubmit }) => {
  const [importValue, setImportValue] = useState("");
  const [error, setError] = useState("");
  const [importSuccess, setImportSuccess] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setImportSuccess("");

    try {
      setImportValue(value);
      JSON.parse(event.target.value);
      setError("");
    } catch (error) {
      setError("Must be in JSON format.");
    }
  };

  const handleSubmit = () => {
    onSubmit(JSON.parse(importValue));
    setError("");
    setImportValue("");
    setImportSuccess("Import successful. Check draft changes.");
  };

  return (
    <div>
      <Success style={{ marginBottom: "10px" }}>{importSuccess}</Success>
      <Textarea
        rows="20"
        style={{ maxWidth: "400px", width: "325px" }}
        value={importValue}
        onChange={handleChange}
        placeholder="Add product license JSON"
      />
      <FieldError>{error}</FieldError>
      <Button disabled={!importValue || error} onClick={handleSubmit}>
        Import
      </Button>
    </div>
  );
};

const OrganizationProductLicenseForm = ({
  licenseDeployment,
  submitLicenseDeploymentRetry,
  refetchDeploymentStatus,
  showCodeView,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { orgId: organizationId } = params;
  const { toasts, toaster, dismissById } = useToaster();

  const [openItems, setOpenItems] = useState([]);

  const fetchCatalog = useAuthenticatedCall(getProductCatalog);
  const fetchOrgs = useAuthenticatedCall((req) =>
    getOrganizations({ ...req, organizationId })
  );

  const resetScroll = () => {
    animate(window.scrollY, 0, {
      duration: 0.5,
      onUpdate: (scrollY) => window.scrollTo(window.scrollX, scrollY),
    });
  };

  // Fetch org licenses
  const {
    data: productLicenses,
    isLoading: isLoadingLicenses,
    refetch: refetchProductLicenses,
    isRefetching: isRefetchingProductLicenses,
  } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchOrgs,
    staleTime: 20 * 1000,
    select: (data) => {
      return data[0].licenses?.products || {};
    },
  });

  // Fetch product catalog
  const {
    error: catalogError,
    data: catalogByProduct,
    isLoading: isLoadingCatalog,
  } = useQuery({
    queryKey: [
      "catalog",
      null,
      {
        include: ["models", "data", "fields", "field_values"],
        // Currently only supporting single value filter,
        // Arrays combine objects as "AND"
        filters: [
          {
            catalog: "products",
            field: "is_license_required",
            operator: "=",
            value: true,
          },
        ],
      },
    ],
    queryFn: fetchCatalog,
    select: (data) => {
      const filteredProducts = Object.keys(data).filter((productId) =>
        productIds.includes(productId)
      );

      return filteredProducts.reduce((catalogByProduct, productId) => {
        return {
          ...catalogByProduct,
          [productId]: data[productId],
        };
      }, {});
    },
  });

  const updateProductLicenses = useAuthenticatedMutation((req) => {
    return updateOrganizationLicenses({
      ...req,
      licenses: {
        products: req.products,
      },
      organizationId,
    });
  });

  const productLicenseMutation = useMutation({
    mutationFn: updateProductLicenses,
    onSuccess: async () => {
      refetchProductLicenses();
      refetchDeploymentStatus();

      // Notify
      toaster.success({
        message: "Product Licenses updated. A deployment has been triggered.",
      });

      clearAuditTrail();

      navigate("./");
    },
    onError: async (error, variables, context) => {
      console.log("productLicenseMutation", { error, variables, context });
    },
    onSettled: () => {
      resetScroll();
    },
  });

  const location = useLocation();
  const locationIsMain = useMemo(
    () =>
      location.pathname
        .split("/")
        .filter((p) => !!p)
        .reverse()[0] === "product",
    [location]
  );

  // On initial mount, throw up a loading toast if catalog if being fetched
  // It won't be fetched if its in the cache already
  const [loadingToastId, setLoadingToastId] = useState(null);
  useEffect(() => {
    if (isLoadingCatalog && !loadingToastId && toasts.length === 0) {
      const catalogLoadingToastId = toaster.info({
        title: "Loading Product Catalog",
        message: "Please wait while we load our product catalog.",
        isInfinite: true,
      });
      setLoadingToastId(catalogLoadingToastId);
    }

    if (!isLoadingCatalog && loadingToastId >= 0) {
      dismissById(loadingToastId);
      setLoadingToastId(null);
    }
  }, [isLoadingCatalog, loadingToastId, dismissById, toaster, toasts.length]);

  const {
    getDynamicProduct,
    getProductIdsEnabled,
    getPreparedLicenses,
    getSettingsControlsConfigured,
    // auditTrail, // primarily used for debugging
    clearAuditTrail,
    getAllProducts,
    importLicense,
  } = useProductLicensingFormState({
    defaultState: defaultLicenseState,
    serverState: productLicenses,
    productCatalog: catalogByProduct,
    isLoading: isLoadingLicenses || isLoadingCatalog,
  });

  const handleProductEnabledToggleChange = (productId, isEnabled) => {
    const product = getDynamicProduct(productId);
    product.page.isEnabled.setValue(isEnabled);
  };

  const [toolsTab, setToolsTab] = useState(null);
  const isLocationFromRoute = location?.state?.from === "route";
  const isFormLoading = isLoadingLicenses || isLoadingCatalog;
  const error = catalogError || productLicenseMutation.error;

  const handleImportSubmit = (importedLicense) => {
    importLicense(importedLicense);
  };

  const [deploymentInProgressTime, setDeploymentInProgressTime] =
    useState("--:--");

  const getDeploymentCounterText = (deployment) => {
    const { status: statusText } = deployment;

    return `${statusText}: ${deploymentInProgressTime}`;
  };

  useEffect(() => {
    let intervalId;

    if (
      licenseDeployment?.rawStatus === "in progress" &&
      !isRefetchingProductLicenses &&
      licenseDeployment?.licenseDeploymentStart
    ) {
      intervalId = setInterval(() => {
        const now = DateTime.now();
        const deploymentTime = licenseDeployment.licenseDeploymentStart;
        const duration = now.diff(deploymentTime, [
          "seconds",
          "minutes",
          "hours",
        ]);
        const { hours } = duration.toObject();

        if (hours === 0) {
          setDeploymentInProgressTime(duration.toFormat("mm:ss"));
        } else {
          setDeploymentInProgressTime(duration.toFormat("hh:mm:ss"));
        }
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }

      setDeploymentInProgressTime("--:--");
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [licenseDeployment, isRefetchingProductLicenses]);

  return (
    <div style={{ marginBottom: "25vh" }}>
      {showCodeView &&
        createPortal(
          <div style={{ width: "350px", marginRight: "-75px" }}>
            <Typography variant="h6">Product License Form (JSON)</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ComboButton
                isActive={!toolsTab || toolsTab === "draft"}
                onClick={() => setToolsTab("draft")}
              >
                Draft
              </ComboButton>
              <ComboButton
                isActive={toolsTab === "saved"}
                onClick={() => setToolsTab("saved")}
              >
                Saved
              </ComboButton>
              <ComboButton
                isActive={toolsTab === "import"}
                onClick={() => setToolsTab("import")}
              >
                Import
              </ComboButton>

              {toolsTab === "saved" && (
                <div style={{ marginLeft: "5px" }}>
                  <CopyButton
                    value={JSON.stringify(productLicenses)}
                    hoverTitle="Copy Config"
                    tooltipDirection="top"
                  />
                  <DownloadButton
                    value={productLicenses}
                    hoverTitle="Download Config"
                    tooltipDirection="top"
                  />
                </div>
              )}
            </div>

            {!toolsTab || toolsTab === "draft" ? (
              <JSONTree
                data={getPreparedLicenses() || "No changes"}
                {...jsonTreeSettings}
              />
            ) : null}

            {toolsTab === "saved" ? (
              <div>
                <JSONTree
                  data={productLicenses || "Not Configured"}
                  {...jsonTreeSettings}
                />
              </div>
            ) : null}
            {toolsTab === "import" ? (
              <div style={{ marginTop: "10px" }}>
                <ImportLicenseForm onSubmit={handleImportSubmit} />
              </div>
            ) : null}
          </div>,
          document.querySelector("#portal__devtools")
        )}

      <Header>
        <Typography variant="h2" noMargin>
          {rootTitle}
        </Typography>
        {locationIsMain && (
          <div>
            {licenseDeployment ? (
              <Pill
                text={
                  licenseDeployment?.status === DEPLOYMENT_STATUSES.IN_PROGRESS
                    ? getDeploymentCounterText(licenseDeployment)
                    : licenseDeployment.status
                }
                textDataCy="licenseStatus"
                size="large"
                variant={
                  DEPLOYMENT_STATUS_PILL_VARIANTS[
                    DEPLOYMENT_STATUS_MAP[licenseDeployment.rawStatus]
                  ]
                }
                tooltipId={tooltipId}
                tooltipContent={
                  ["succeeded", "timeout", "failed"].includes(
                    licenseDeployment.rawStatus
                  )
                    ? licenseDeployment.tooltip
                    : null
                }
              />
            ) : (
              <Skeleton style={{ width: "50px", height: "30px" }} />
            )}
          </div>
        )}
      </Header>

      <ErrorBoundary
        onError={(error) => console.log(error)}
        fallback={
          <Error>
            We encountered an issue while attempting to load this page. Please
            contact support for assistance.
          </Error>
        }
      >
        <AnimatePresence mode="wait">
          <motion.div
            initial={{
              opacity: isLocationFromRoute ? 0 : 1,
            }}
            exit={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              opacity: {
                duration: 0.4,
              },
            }}
            style={{
              marginBottom: locationIsMain ? "15px" : "0px",
              fontSize: "14px",
              fontFamily: "Inter",
            }}
          >
            {locationIsMain &&
              licenseDeployment?.licenseLastSuccessfulDeployment &&
              `Last successful deployment: ${licenseDeployment.licenseLastSuccessfulDeployment.toFormat(
                dateTimeFormat
              )}`}
          </motion.div>
          <Routes location={location} key={location.pathname}>
            <Route
              index
              element={
                <motion.div
                  initial={{
                    opacity: isLocationFromRoute ? 0 : 1,
                    y: isLocationFromRoute ? 20 : 0,
                  }}
                  exit={{
                    opacity: 0,
                    y: 20,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    opacity: {
                      duration: 0.4,
                    },
                    y: {
                      duration: 0.3,
                    },
                  }}
                >
                  {licenseDeployment?.status ===
                  DEPLOYMENT_STATUSES.IN_PROGRESS ? (
                    <InfoFlash style={{ marginBottom: "10px" }}>
                      This page is restricted to read-only while a deployment is
                      in progress.
                    </InfoFlash>
                  ) : licenseDeployment?.status ===
                    DEPLOYMENT_STATUSES.QUEUED ? (
                    <InfoFlash
                      style={{
                        backgroundColor: "#dcdcdc",
                        color: "#000000",
                        fontWeight: "400",
                        marginBottom: "10px",
                      }}
                    >
                      This page is restricted to read-only while a deployment is
                      queued.
                    </InfoFlash>
                  ) : (
                    <Error style={{ marginBottom: "10px" }}>{error}</Error>
                  )}
                  <ProductBundleAccordion
                    openItems={openItems}
                    setOpenItems={setOpenItems}
                    bundles={PRODUCT_BUNDLES}
                    products={getAllProducts()}
                    productsEnabled={getProductIdsEnabled()}
                    handleProductToggle={handleProductEnabledToggleChange}
                    isLoading={isFormLoading}
                    settingsControlsConfigured={getSettingsControlsConfigured()}
                    isReadOnly={[
                      DEPLOYMENT_STATUSES.IN_PROGRESS,
                      DEPLOYMENT_STATUSES.QUEUED,
                    ].includes(licenseDeployment?.status)}
                  />

                  <Row style={{ justifyContent: "flex-end", gap: "20px" }}>
                    {!licenseDeployment ||
                    [
                      DEPLOYMENT_STATUSES.DEFAULT,
                      DEPLOYMENT_STATUSES.IN_PROGRESS,
                      DEPLOYMENT_STATUSES.QUEUED,
                      DEPLOYMENT_STATUSES.SUCCESS,
                    ].includes(licenseDeployment?.status) ? null : (
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={submitLicenseDeploymentRetry.mutate}
                        isLoading={submitLicenseDeploymentRetry.isPending}
                        width="fit-content"
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px",
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowsRotate} />
                          <span>Retry Deployment</span>
                        </span>
                      </Button>
                    )}

                    {!!getPreparedLicenses() && (
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={clearAuditTrail}
                        width="fit-content"
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px",
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowRotateLeft} />
                          <span>Reset</span>
                        </span>
                      </Button>
                    )}

                    <ButtonLink
                      to="confirm"
                      disabled={!getPreparedLicenses()}
                      width="fit-content"
                    >
                      Review Configuration
                    </ButtonLink>
                  </Row>
                </motion.div>
              }
            />
            {catalogByProduct
              ? productRoutes.map((route, index) => {
                  const { path, product, breadcrumbs } = route;
                  const isFirstRoute = index === 0;
                  const isLastRoute = index === productRoutes.length - 1;
                  const prevRoute = productRoutes[index - 1];
                  const nextRoute = productRoutes[index + 1];
                  const dynamicProduct = getDynamicProduct(product.id);

                  return (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <div style={{ position: "relative" }}>
                          <ProductPageBreadcrumbs breadcrumbs={breadcrumbs} />
                          <Error style={{ marginBottom: "10px" }}>
                            {error}
                          </Error>
                          <AnimatedProductPageContainer>
                            <ProductPage product={dynamicProduct} />

                            <Row
                              style={{
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                {!isFirstRoute ? (
                                  <ButtonLink
                                    to={`../${prevRoute.path}`}
                                    state={{ from: location.pathname }}
                                    onClick={() => resetScroll()}
                                    variant="outlined"
                                    style={{
                                      width: "fit-content",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowLeft}
                                      size="sm"
                                      style={{ marginBottom: "-1px" }}
                                    />
                                    <div>{prevRoute.product.meta.name}</div>
                                  </ButtonLink>
                                ) : null}
                                {!isLastRoute ? (
                                  <ButtonLink
                                    to={`../${nextRoute.path}`}
                                    state={{ from: location.pathname }}
                                    onClick={() => resetScroll()}
                                    variant="contained"
                                    style={{
                                      width: "fit-content",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: "10px",
                                    }}
                                  >
                                    <div>{nextRoute.product.meta.name}</div>
                                    <FontAwesomeIcon
                                      icon={faArrowRight}
                                      size="sm"
                                      style={{ marginBottom: "-1px" }}
                                    />
                                  </ButtonLink>
                                ) : null}
                                {isLastRoute ? (
                                  <ButtonLink
                                    to="../confirm"
                                    disabled={!getPreparedLicenses()}
                                    width="fit-content"
                                  >
                                    Review Configuration
                                  </ButtonLink>
                                ) : null}
                              </div>
                            </Row>
                          </AnimatedProductPageContainer>
                        </div>
                      }
                    />
                  );
                })
              : null}
            <Route
              path="confirm"
              element={
                <motion.div
                  initial={{
                    opacity: isLocationFromRoute ? 0 : 1,
                    y: isLocationFromRoute ? 20 : 0,
                  }}
                  exit={{
                    opacity: 0,
                    y: 20,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    opacity: {
                      duration: 0.4,
                    },
                    y: {
                      duration: 0.3,
                    },
                  }}
                >
                  <ProductPageBreadcrumbs
                    breadcrumbs={[
                      { title: rootTitle, to: "../" },
                      { title: "Deployment Review" },
                    ]}
                  />
                  <Error style={{ marginBottom: "10px" }}>{error}</Error>
                  <DeploymentReview
                    bundles={PRODUCT_BUNDLES}
                    products={getAllProducts()}
                    productsEnabled={getProductIdsEnabled()}
                    getDynamicProduct={getDynamicProduct}
                    handleSubmit={() => {
                      const preparedUpdates = getPreparedLicenses();
                      console.log({ preparedUpdates });

                      if (preparedUpdates) {
                        productLicenseMutation.mutate(preparedUpdates);
                      }
                    }}
                    isSubmitting={productLicenseMutation.isPending}
                    isDisabled={!getPreparedLicenses()}
                    isLoading={isFormLoading}
                  />
                </motion.div>
              }
            />
          </Routes>
        </AnimatePresence>
      </ErrorBoundary>
    </div>
  );
};

export default OrganizationProductLicenseForm;

// const reverseKeyDictionary = {
//   identified_consumers: "activated_consumers",
//   identified_consumer_record_limit: "activation_limit",
// };
// const reverseKeyDictionary = Object.entries(keyDictionary).reduce(
//   (dict, entry) => {
//     const [key, value] = entry;
//     dict[value] = key;

//     return dict;
//   },
//   {}
// );
// const translatedUpdates = objectWithTranslatedKeys(
//   incrementalUpdates,
//   reverseKeyDictionary
// );

// const ignoreKeys = ["enabled", "anonymized_records"];
// const filteredUpdates = objectWithFilteredKeys(
//   translatedUpdates,
//   ignoreKeys
// );
// const sanitizedUpdates = Object.entries(filteredUpdates).reduce(
//   (sanitizedUpdates, entry) => {
//     const [key, value] = entry;

//     if (value.license && Object.keys(value.license).length) {
//       sanitizedUpdates[key] = value;
//     } else if (!value.license && Object.keys(value).length) {
//       sanitizedUpdates[key] = value;
//     }

//     return sanitizedUpdates;
//   },
//   {}
// );
