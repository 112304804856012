import { email, required, validate } from "common/validation";
import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Input from "core/components/Form/Input";

const LoginForm = ({ onSubmit, defaultValues, error }) => {
  return (
    <Form
      onSubmit={onSubmit}
      submitLabel="Log In"
      defaultValues={defaultValues}
    >
      <Error>{error}</Error>
      <Input
        label="Email"
        field="email"
        type="email"
        validate={validate(required(), email())}
      />
      <Input
        label="Password"
        field="password"
        type="password"
        validate={validate(required())}
      />
    </Form>
  );
};

export default LoginForm;
