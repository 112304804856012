import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import {
  faListUl,
  faFileCertificate,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faBan } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";

import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { getUsers, updateUser } from "modules/admin/actions";
import useToaster from "core/hooks/useToaster";
import { FLAG_KEYS, ROLES, TABLEAU_ROLES } from "core/constants";
import Typography from "core/components/Typography";
import { Column, Row } from "core/styles";
import Pill from "core/components/Pill";
import Flex from "core/components/Flex";
import DetailsViewer from "modules/admin/DetailsViewer";
import { SideMenuContainer, SubNav } from "modules/admin/SubNav";
import UserForm from "modules/admin/UserForm";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import useLocalStore from "core/hooks/useLocalStore";
import { CredentialNavSection } from "modules/admin/CredentialNavSection";
import { useCheckFeatureFlag } from "core/hooks/featureFlagging";

const getUserStatus = ({ isActive }) =>
  isActive ? USER_STATUSES.ACTIVE : USER_STATUSES.INACTIVE;

const USER_STATUSES = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

const USER_STATUS_VARIANTS = {
  Active: "success",
  Inactive: "default",
};

const tooltipId = "statusTooltip";

const defaultUser = {
  active: true,
  status: getUserStatus({ isActive: true }),
  email: "",
  name: "",
  organizationId: "",
  role: "",
  username: "",
  tableauConfig: { tableauLicense: "" },
  direct_data_access_enabled: false,
};

const DetailsNavigationMenu = ({ isLoading, user }) => {
  const location = useLocation();
  const routePaths = location.pathname.split("/");
  const trimmedPaths = routePaths.filter((path) => path.trim());
  const reversedPaths = trimmedPaths.reverse();
  const slicedRoutePaths = reversedPaths.slice(0, 3);

  const hasDataAccessFeature = useCheckFeatureFlag(
    FLAG_KEYS.DATA_ACCESS_MANAGEMENT
  );

  return (
    <SideMenuContainer>
      <SubNav
        className={
          ["profile", ""].includes(slicedRoutePaths[0]) ? "active" : ""
        }
        to="./"
        icon={faListUl}
        label="General"
        statusIcon={user.active ? faCheck : faBan}
        statusVariant={USER_STATUS_VARIANTS[user.status]}
        isStatusLoading={isLoading}
      />
      {hasDataAccessFeature && (
        <SubNav
          className={slicedRoutePaths.includes("credentials") ? "active" : ""}
          to="credentials"
          icon={faFileCertificate}
          label="Data Credentials"
          isDisabled={!user.direct_data_access_enabled || isLoading}
        />
      )}
    </SideMenuContainer>
  );
};

const UserProfile = () => {
  const { toaster } = useToaster();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const { email, orgId: organizationId } = params;

  const fetchQueryCall = useAuthenticatedCall((req) =>
    getUsers({ ...req, email })
  );

  const { currentUser, setCurrentUser } = useCurrentUser();
  const localStore = useLocalStore();

  const queryKey = ["users", organizationId, { email }];

  const {
    isError,
    error,
    isPlaceholderData, // isLoading is now false, so this replaces that while we fetch fresh data
    data: profileUser,
  } = useQuery({
    queryKey,
    queryFn: fetchQueryCall,
    staleTime: 20 * 1000,
    refetchOnMount: true,
    select: (data) => {
      if (Array.isArray(data) && data.length === 1) {
        const userData = data[0];

        return {
          ...defaultUser,
          ...userData,
          status: getUserStatus({ isActive: userData.active }),
          roleLabel: ROLES[userData.role],
        };
      }

      return defaultUser;
    },
    placeholderData: defaultUser,
  });

  useEffect(() => {
    if (isError) {
      if (Array.isArray(error)) {
        toaster.error({ message: error[0] });
      } else {
        toaster.error({ message: error.message });
      }
    }
  }, [isError, error, toaster]);

  const apiCall = useAuthenticatedMutation((user) => {
    return updateUser({ ...user, organizationId });
  });

  const mutation = useMutation({
    mutationFn: apiCall,
    onSuccess: async (data, variables, context) => {
      // If a query successfully returns while this is working, the local storage will update,
      // but it will pull the old storage values and overwrite the "currentUser" object
      if (variables.email === currentUser.email) {
        localStore.setKey(localStore.KEYS.NAME, variables.name);
        localStore.setKey(localStore.KEYS.USER_ROLE, ROLES[variables.role]);
        setCurrentUser((previousUser) => {
          return {
            ...previousUser,
            name: variables.name,
            role: ROLES[variables.role],
          };
        });
      }

      // Update detail entry of user record in cache from updates in form
      queryClient.setQueryData(queryKey, (cachedUsers) => {
        const updatedUser = { ...cachedUsers[0], ...variables };

        // If we have already fetched the entire organizations user list
        // Lets just update this user's record in the cache
        const cachedUserList = queryClient.getQueryData([
          "users",
          organizationId,
        ]);

        if (cachedUserList) {
          queryClient.setQueryData(["users", organizationId], (cachedList) => {
            if (!cachedList) {
              return [];
            }

            return cachedList.map((cachedUser) => {
              if (cachedUser.email === updatedUser.email) {
                return {
                  ...cachedUser,
                  ...updatedUser,
                  isUpdated: true,
                };
              }

              return cachedUser;
            });
          });
        }

        return [updatedUser];
      });

      // Invalidate cached query results
      queryClient.invalidateQueries({ queryKey });

      // Close form
      navigate(`/admin/org-management/${organizationId}`);

      // Notify
      toaster.success({ message: "User has been updated." });
    },
  });

  return (
    <div style={{ position: "relative", padding: "80px 64px" }}>
      <Flex direction="column">
        <AnimatePresence mode="wait">
          <Flex directionSm="column" direction="row" gap="7vw">
            <Flex direction="column">
              <Column gap="large" alignItems="start">
                <Row gap="15px" alignItems="center">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      width: "48px",
                      height: "48px",
                      padding: "10px 12px",
                      paddingBottom: "11px",
                      color: "#fff",
                      backgroundColor: "#00C1F3",
                      borderRadius: "5px",
                    }}
                  />
                  <div>
                    <Typography
                      variant="h3"
                      noMargin
                      style={{ maxWidth: "230px" }}
                    >
                      {isPlaceholderData || isError ? (
                        <Skeleton style={{ width: "150px" }} />
                      ) : (
                        profileUser.name
                      )}
                    </Typography>
                    <Typography variant="h5" noMargin>
                      {isPlaceholderData || isError ? (
                        <Skeleton style={{ width: "150px" }} />
                      ) : (
                        profileUser.roleLabel
                      )}
                    </Typography>
                  </div>
                </Row>

                <DetailsNavigationMenu
                  isUserFetching={isPlaceholderData}
                  user={profileUser}
                />

                <div id="portal__devtools" />
              </Column>
            </Flex>

            <Flex direction="column" minWidth="450px" width="min-content">
              <Routes>
                <Route
                  index
                  element={
                    <div>
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        marginBottom="5px"
                      >
                        <Typography variant="h2" noMargin>
                          General
                        </Typography>
                        <div>
                          {isPlaceholderData ? (
                            <Skeleton
                              style={{ width: "50px", height: "30px" }}
                            />
                          ) : (
                            <Pill
                              text={profileUser.status}
                              size="large"
                              variant={USER_STATUS_VARIANTS[profileUser.status]}
                              tooltipId={tooltipId}
                              tooltipStrategy="fixed"
                              textDataCy="userProfileStatus"
                            />
                          )}
                        </div>
                      </Flex>
                      {[ROLES.systemAdmin, ROLES.orgAdmin].includes(
                        currentUser.role
                      ) ? (
                        <UserForm
                          onSubmit={mutation.mutate}
                          defaultValues={profileUser}
                          error={mutation.error}
                          isFetching={isPlaceholderData || isError}
                          isSubmitting={mutation.isPending}
                        />
                      ) : (
                        <DetailsViewer
                          isLoading={isPlaceholderData}
                          data={[
                            { label: "Name", value: profileUser?.name },
                            { label: "Email", value: profileUser?.email },
                            {
                              label: "Alternate Email",
                              value: profileUser?.alternateEmail || "None",
                            },
                            {
                              label: "Role",
                              value: profileUser?.roleLabel,
                            },
                            {
                              label: "BI Portal User",
                              value:
                                TABLEAU_ROLES[
                                  profileUser?.tableauConfig.tableauLicense
                                ] || "None",
                            },
                            {
                              label: "Direct Data Access",
                              value: profileUser?.direct_data_access_enabled
                                ? "Enabled"
                                : "Disabled",
                            },
                          ]}
                        />
                      )}
                    </div>
                  }
                />
                <Route
                  path="credentials"
                  element={
                    <motion.div style={{ marginBottom: "30vh" }}>
                      <Typography variant="h2">Data Credentials</Typography>
                      <CredentialNavSection
                        type="user"
                        entity={profileUser}
                        isLoading={isPlaceholderData}
                        onMutationSuccess={() => {
                          queryClient.invalidateQueries({
                            queryKey: ["users", organizationId, { email }],
                          });
                        }}
                      />
                    </motion.div>
                  }
                />
              </Routes>
            </Flex>
          </Flex>
        </AnimatePresence>
      </Flex>

      <Tooltip
        id={tooltipId}
        style={{ maxWidth: "450px", zIndex: "10000" }}
        offset={15}
      />
    </div>
  );
};

export default UserProfile;
