import { useEffect, useMemo, useState } from "react";

const deepMergeObjects = (prevUpdates = {}, nextUpdates = {}) => {
  if ([prevUpdates, nextUpdates].includes(null)) {
    return prevUpdates ?? nextUpdates;
  }

  const uniqueKeys = [
    ...new Set([...Object.keys(prevUpdates), ...Object.keys(nextUpdates)]),
  ];

  return uniqueKeys.reduce((mergedValues, key) => {
    const prevValue = prevUpdates[key];
    const nextValue = nextUpdates[key];

    if ([prevValue, nextValue].includes(undefined)) {
      return {
        ...mergedValues,
        [key]: nextValue ?? prevValue,
      };
    }

    const isPrevValueObject =
      prevValue && !Array.isArray(prevValue) && typeof prevValue === "object";
    const isNextValueObject =
      nextValue && !Array.isArray(nextValue) && typeof nextValue === "object";

    if (isPrevValueObject && isNextValueObject) {
      return {
        ...mergedValues,
        [key]: deepMergeObjects(prevValue, nextValue, "recursive"),
      };
    } else {
      return {
        ...mergedValues,
        [key]: nextValue,
      };
    }
  }, {});
};

const buildComplexFieldState = ({ prevState = {}, fieldKey, newValue }) => {
  if (!fieldKey.includes(".")) {
    return { ...prevState, [fieldKey]: newValue };
  } else {
    const splitFieldKey = fieldKey.split(".");
    const nextKey = splitFieldKey.shift();

    return {
      ...prevState,
      [nextKey]: splitFieldKey.length
        ? buildComplexFieldState({
            prevState: prevState[nextKey],
            fieldKey: splitFieldKey.join("."),
            newValue,
          })
        : newValue,
    };
  }
};

const useForm = ({
  disabledUntilTouched = false,
  placeholderValues = {},
  initialValues = {},
  isFetchingInitialValues = false,
  formSchema,
  onSubmit,
}) => {
  const [draftValues, setDraftValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isFetchingInitialValues) {
      setDraftValues(initialValues);
    }
  }, [initialValues, isFetchingInitialValues]);

  const isValid = useMemo(() => {
    const isFormValid = formSchema.safeParse({
      ...initialValues,
      ...draftValues,
    });

    return isFormValid.success;
  }, [formSchema, draftValues, initialValues]);

  const isDisabled = useMemo(
    () => (disabledUntilTouched && !isTouched) || !isValid,
    [disabledUntilTouched, isTouched, isValid]
  );

  const onFieldChange = useMemo(
    () => (fieldKey) => (value, error) => {
      setIsTouched(true);

      setDraftValues((previousState) => {
        return buildComplexFieldState({
          prevState: previousState,
          fieldKey,
          newValue: value,
        });
      });

      setErrors((previousState) => {
        return buildComplexFieldState({
          prevState: previousState,
          fieldKey,
          newValue: error,
        });
      });
    },
    [setIsTouched, setDraftValues, setErrors]
  );

  const onFormSubmit = (event) => {
    // Commenting this out until we remove react-form
    // event.preventDefault();

    if (isValid) {
      onSubmit(draftValues);
      setIsTouched(false);
    }

    return false;
  };

  return {
    values: deepMergeObjects(
      { ...placeholderValues, ...initialValues },
      draftValues
    ),
    errors,
    isValid,
    isTouched,
    isDisabled,
    onFieldChange,
    onFormSubmit,
  };
};

export default useForm;
