import { z } from "zod";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

import Error from "core/components/Error";
import Form from "core/components/Form/Form";
import Field from "core/components/Field";
import OrgIdTooltip from "./OrgIdTooltip";
import useForm from "core/hooks/useForm";
import { DEFAULT_ORG } from "core/constants";
import {
  domain,
  email,
  lettersAndNumbers,
  lowercase,
  maxLength,
  minLength,
  notEquals,
  required,
  startsWithLetter,
  validate,
  objectOrArrayRequired,
  noDuplicateArrayItems,
} from "common/validation";

// TODO: Update this to match field level design and come up with good pattern for re-use between form and field
const formSchema = z.object({
  id: z.string().min(2).max(10),
  name: z.string().min(2),
  primaryContact: z.object({
    name: z.string().min(2),
    email: z.string().email(),
  }),
  domain: z.array(z.string().min(3)).min(1),
});

const OrganizationDetailForm = ({
  onSubmit,
  initialValues,
  isFetching = false,
  isSubmitting,
  onCancel,
  isCancelling,
  error,
}) => {
  const { values, errors, isDisabled, onFormSubmit, onFieldChange } = useForm({
    placeholderValues: DEFAULT_ORG,
    initialValues,
    disabledUntilTouched: true,
    onSubmit,
    formSchema,
    isFetchingInitialValues: isFetching,
  });

  return (
    <Form
      onSubmit={onFormSubmit}
      submitLabel={`${initialValues?.id ? "Save Changes" : "Next"}`}
      onCancel={onCancel}
      cancelLabel="Retry Setup"
      cancelIcon={faArrowsRotate}
      isCancelLoading={isCancelling}
      isFetching={isFetching}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
      // Removing mode to control form entirely with isDisabled above
      // mode={!!values?.id ? "update" : "create"}
    >
      <Error>{error}</Error>
      <Field
        type="text"
        id="name"
        labelText="Organization Name"
        placeholder="Enter name"
        autoComplete="organization"
        showSkeleton={isFetching}
        isRequired={true}
        value={values.name}
        error={errors.name}
        onChange={onFieldChange("name")}
        validator={validate(required(), minLength(2))}
      />

      <Field
        type="text"
        id="id"
        labelText="Organization ID"
        labelHint={<OrgIdTooltip />}
        placeholder="Enter unique id"
        autoComplete="organization-id"
        showSkeleton={isFetching}
        isRequired={true}
        value={values.id}
        error={errors.id}
        isDisabled={Boolean(initialValues?.id)}
        onChange={onFieldChange("id")}
        validator={validate(
          required(),
          startsWithLetter(),
          lowercase(),
          lettersAndNumbers(),
          minLength(2),
          maxLength(10),
          notEquals("hosting", "populi")
        )}
      />

      <Field
        type="text"
        id="primaryContact.name"
        labelText="Primary Contact"
        placeholder="Enter name"
        autoComplete="name"
        showSkeleton={isFetching}
        isRequired={true}
        value={values.primaryContact.name}
        error={errors.primaryContact?.name}
        onChange={onFieldChange("primaryContact.name")}
        validator={validate(required(), minLength(2))}
      />
      <Field
        type="text" // TODO Email input
        id="primaryContact.email"
        labelText="Primary Email"
        placeholder="Enter email"
        autoComplete="email"
        showSkeleton={isFetching}
        isRequired={true}
        value={values.primaryContact.email}
        error={errors.primaryContact?.email}
        onChange={onFieldChange("primaryContact.email")}
        validator={validate(required(), email())}
      />
      <Field
        type="text"
        id="domain"
        labelText="Domain"
        placeholder="Enter domain"
        showSkeleton={isFetching}
        isMultiValue={true}
        isRequired={true}
        value={values.domain}
        error={errors.domain}
        onChange={onFieldChange("domain")}
        validator={validate(
          objectOrArrayRequired(),
          domain(),
          noDuplicateArrayItems()
        )}
      />
    </Form>
  );
};

export default OrganizationDetailForm;
