import TextField from "./TextField";
import PasswordField from "./PasswordField";
import NumberField from "./NumberField";
import TextArea from "./TextArea";
import Combobox from "./Combobox";
import Toggle from "./Toggle";
import Slider from "./Slider";
import Radio from "./Radio";
import DatePicker from "../DatePicker";

const NA = () => {
  return <div>not implemented</div>;
};

export const CONTROLS = {
  text: TextField,
  email: TextField,
  password: PasswordField,
  number: NumberField,
  textarea: TextArea,
  combobox: Combobox,
  toggle: Toggle,
  checkbox: NA,
  slider: Slider,
  radio: Radio,
  date: DatePicker,
  search: NA,
};
