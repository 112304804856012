import useAuth from "./useAuth";
import useCurrentUser from "./useCurrentUser";

// A wrapper for the the function passed to useMutate
// Provides currentUser's accessToken to the underlying api call invoked
const useAuthenticatedMutation = (apiCall) => {
  const { refresh } = useAuth();
  const { currentUser } = useCurrentUser();

  let { accessToken, expirationTime } = currentUser || {};

  return async (requestParams) => {
    const now = Date.now();

    if (expirationTime < now) {
      try {
        const result = await refresh();
        const { accessToken } = result;

        return apiCall({
          ...requestParams,
          accessToken,
        });
      } catch (error) {
        throw error;
      }
    } else {
      return apiCall({
        ...requestParams,
        accessToken,
      });
    }
  };
};

export default useAuthenticatedMutation;
