import { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

const useTheme = () => {
  const { themeMode, setThemeMode, theme } = useContext(ThemeContext);

  return {
    themeMode,
    setThemeMode,
    theme,
  };
};

export default useTheme;
