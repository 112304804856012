import { createContext } from "react";

const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => {},
  isPasswordExpired: null,
  setIsPasswordExpired: () => {},
  initializing: false,
  setInitializing: () => {},
  isIdentified: false,
  setIsIdentified: () => {},
});

export default UserContext;
