import { Navigate } from "react-router-dom";

import Page from "core/components/Page";
import Link from "core/components/Link";
import { useMemo, useState } from "react";
import ExpiringPasswordForm from "modules/auth/ExpiringPasswordForm";
import { updatePassword } from "modules/auth/actions";
import useLocalStore from "core/hooks/useLocalStore";
import useToaster from "core/hooks/useToaster";
import styled from "styled-components";
import useCurrentUser from "core/hooks/useCurrentUser";
import { DateTime } from "luxon";
import useAuthenticatedMutation from "core/hooks/useAuthenticatedMutation";
import Typography from "core/components/Typography";
import { Grid, GridColumn } from "core/styles";

const defaultValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

const Info = styled.div`
  background-color: #006dcc;
  width: 100%;
  color: white;
  font-size: 13px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  padding: 10px 30px;
  border-radius: 5px;
`;

const ExpiringPassword = () => {
  const [error, setError] = useState("");
  const { currentUser, setCurrentUser } = useCurrentUser();
  const localStore = useLocalStore();
  const { toaster } = useToaster();

  const daysRemaining = useMemo(() => {
    if (!currentUser) {
      return 0;
    }

    const passwordMeta = currentUser.passwordMeta;
    const passwordLastUpdated = DateTime.fromMillis(passwordMeta.lastUpdated);
    const daysSincePasswordLastUpdated = Math.floor(
      DateTime.now().diff(passwordLastUpdated, "days").toObject().days
    );

    return (
      currentUser.passwordMeta.expirationPeriod - daysSincePasswordLastUpdated
    );
  }, [currentUser]);

  const apiUpdatePassword = useAuthenticatedMutation(updatePassword);

  if (currentUser && !currentUser.isPasswordExpiring) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (values) => {
    setError("");

    try {
      await apiUpdatePassword(values);

      toaster.success("Password has been updated successfully");
      dismiss();
    } catch (error) {
      setError(error);
    }
  };

  const dismiss = () => {
    localStore.setKey(localStore.KEYS.IS_PASSWORD_EXPIRING, false);
    setCurrentUser((user) => ({ ...user, isPasswordExpiring: false }));
  };

  return (
    <Page>
      <Grid columns="350px" justify="center" lift="15vh">
        <GridColumn align="center">
          <img src="/dh-logo.svg" alt="logo" height="66px" />

          <Info style={{ marginTop: "20px" }}>
            Your password is set to expire in{" "}
            <b>
              {daysRemaining} day
              {daysRemaining !== 1 ? "s" : ""}
            </b>
            .<br />
            Please update your password below.
            <br />
            Passwords must be at least 8 characters.
            <br />
          </Info>

          <ExpiringPasswordForm
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={error}
          />
          <Typography variant="p" style={{ textAlign: "center" }}>
            <Link onClick={dismiss}>Remind me later</Link>
          </Typography>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default ExpiringPassword;
